import React from "react"
import { DateRangePicker as DateRangePickerBase } from "@mindz/react-admin-ui"

import { withStyles } from "@mindz/react-hoc"

import styles from "./index.module.css"

export default withStyles(styles)(({ children, ...props }) => (
    <DateRangePickerBase.Kaufland {...props}>{children}</DateRangePickerBase.Kaufland>
))
