import React, { Component } from "react"

import { withStyles } from "@mindz/react-hoc"
import styles from "./index.module.css"

class SnakeLoader extends Component {
    render() {
        const { type, cx } = this.props
        return <div className={cx("spinner", type)} />
    }
}

export default withStyles(styles)(SnakeLoader)
