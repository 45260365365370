import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import getCxFromStyles from "helpers/getCxFromStyles"
import styles from "./index.module.css"
import { STATUSES } from "constances"

import ArrowUpIcon from "assets/ico-arrow-up.module.svg"
import ChangeIcon from "assets/ico-change.module.svg"
import TrashIcon from "assets/ico-trash.module.svg"
import MailIcon from "assets/ico-mail.module.svg"
import { SubmissionsStoreContext } from "store/SubmissionsStore"

import SendOrDeleteModal from "components/SendOrDeleteModal"

const cx = getCxFromStyles(styles)

const initialSendOrDeleteData = {
    isOpen: false,
    type: ""
}

const restOfStatuses = status =>
    ["rejected", "in_progress", "accepted"].filter(s => s !== status.replace(/_by_system$/, ""))

const SendOrDelete = ({ mailSent, mobile, deleteSubmission, sendMail }) => (
    <div className={cx("sendOrDelete", { mobile })}>
        <button onClick={deleteSubmission}>
            <TrashIcon />
            Usuń zgłoszenie
        </button>
        <button className={cx("sendRejectedInfo", { disabled: mailSent })} onClick={sendMail} disabled={mailSent}>
            <MailIcon />
            Wyślij wiadomość
        </button>
    </div>
)

const StatusBox = ({ currentSubmissionData, handleOpenChangeTypeModal, history }) => {
    const { patchSubmissionStatus, sendMessageToRejected, deleteSubmission } = useContext(SubmissionsStoreContext)
    const [expanded, setExpanded] = useState(false)
    const [sendOrDeleteData, setSendOrDelete] = useState(initialSendOrDeleteData)
    const { id, status, compatibility, mail_sent: mailSent } = currentSubmissionData
    const { text, color, backgroundColor, hint, title, description } = STATUSES[status]
    const isDecidedStatus = ["accepted", "rejected", "rejected_by_system"].includes(status)
    const isRejected = ["rejected", "rejected_by_system"].includes(status)

    const closeSendOrDeleteModal = () => setSendOrDelete(initialSendOrDeleteData)
    const openSendOrDeleteModal = type => setSendOrDelete({ isOpen: true, type })

    const submitSendOrDelete = () => {
        const { type } = sendOrDeleteData
        const redirectToList = () => history.push("/panel/zgloszenia")
        if (type === "send") {
            sendMessageToRejected([id], true)
        }
        if (type === "delete") {
            deleteSubmission([id], redirectToList)
        }
    }

    return (
        <aside
            className={cx("root", {
                isDecidedStatus,
                expanded,
                redMobileBg: status.includes("rejected"),
                greenMobileBg: status === "accepted"
            })}
        >
            <header style={{ backgroundColor }}>
                Status zgłoszenia:
                <br /> <span style={{ color, fontWeight: 500 }}>{text}</span>
            </header>

            <section className={cx("title")}>
                {title && <h4>{title}</h4>}
                <div className={cx("compatibility")}>
                    <strong>{compatibility}%</strong>zgodnych odpowiedzi
                </div>
                {description && <span className={cx("description")}>{description}</span>}
            </section>

            {isRejected && (
                <SendOrDelete
                    mailSent={mailSent}
                    deleteSubmission={() => openSendOrDeleteModal("delete")}
                    sendMail={() => openSendOrDeleteModal("send")}
                />
            )}

            {(expanded || !isDecidedStatus) && (
                <section className={cx("actions")}>
                    <span className={cx("actionsTitle")}>
                        {isDecidedStatus ? "Zmień status na:" : "Oznacz zgłoszenie jako:"}
                    </span>
                    {restOfStatuses(status).map(s => {
                        const { text } = STATUSES[s]
                        return (
                            <div key={s} className={cx("action")} onClick={() => patchSubmissionStatus(id, s)}>
                                <span className={cx([s])}>{text}</span>
                            </div>
                        )
                    })}
                </section>
            )}

            <div className={cx("expandButton")} onClick={() => setExpanded(!expanded)}>
                {expanded ? "Zwiń" : "Zmień status"} <ArrowUpIcon />
            </div>

            {hint && <footer>{hint}</footer>}

            <div className={cx("actionsButtons")}>
                <button className={cx("changeType")} onClick={() => handleOpenChangeTypeModal()}>
                    <ChangeIcon />
                    Zmień typ zgłoszenia
                </button>
                {isRejected && <SendOrDelete mobile mailSent={mailSent} />}
            </div>
            <SendOrDeleteModal
                {...sendOrDeleteData}
                forAll={false}
                closeModal={closeSendOrDeleteModal}
                submitForm={submitSendOrDelete}
                deleteSubmission={() => openSendOrDeleteModal("delete")}
                sendMail={() => openSendOrDeleteModal("send")}
            />
        </aside>
    )
}

StatusBox.propTypes = {
    currentSubmissionData: PropTypes.shape({
        id: PropTypes.isRequired,
        status: PropTypes.oneOf(["new", "in_progress", "accepted", "rejected", "rejected_by_system"]).isRequired
    }).isRequired
}

export default withRouter(StatusBox)
