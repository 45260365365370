import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import styles from './index.module.css'

class Title extends Component {
    render() {
        const { cx, children } = this.props
        return <div className={cx('title')}>{children}</div>
    }
}

export default withStyles(styles)(Title)