import React from "react"

import AbstractStore from "./AbstractStore"
import fetch from "helpers/fetch"
import { NotificationManager } from "react-notifications"
import steps from "config/formSteps"
import scenarios from "config/formScenarios"
import { VALIDATION_IGNORED_SECTIONS, MESSAGES } from "constances"

export const FormStoreContext = React.createContext()

class FormStore extends AbstractStore {
    isChangedScenario = false

    handleSetStepIndex = stepIndex => {
        const { stepIndex: prevStepIndex, errors } = this.state
        const isValid = prevStepIndex < stepIndex ? this.validateSections() : true
        if (isValid) {
            this.setState({
                stepIndex,
                errors: prevStepIndex < stepIndex ? errors : {}
            })
        }

        return isValid
    }

    handlePostForm = () => {
        const { sections, currentScenario } = this.state

        const sectionRepresentant =
            currentScenario === "private" ? "section_representant_private" : "section_representant"
        const contact_email = (sections && sections[sectionRepresentant] && sections[sectionRepresentant].email) || ""

        if (!this.validateSections()) {
            return
        }

        this.setState({
            isSending: true
        })
        fetch
            .postRAW("/forms", {
                type: this.getScenarioType(),
                content: {
                    ...sections
                },
                contact_email,
                attachment_path: (sections && sections.section_attachment && sections.section_attachment.url) || ""
            })
            .then(() => {
                document.location.href = "/formularz/dziekujemy"
            })
            .catch(error => {
                this.setState({
                    isSending: false
                })
                NotificationManager.error(
                    error && error.message ? error.message : "Nie udało się wysłać formularza. Spróbuj jeszcze raz.",
                    MESSAGES.NOTIFICATION_ERROR_HEADER
                )
            })
    }

    getScenarioType = () => {
        const { currentScenario } = this.state

        if (currentScenario === "non_profit_mini") {
            return "mini_non_profit"
        }
        if (currentScenario === "commercial_mini") {
            return "minisponsoring"
        }

        return currentScenario
    }

    handleSetSectionValue = ({ section, value, isValidationDisabled }) => {
        const { sections, currentScenario } = this.state

        let scenario = currentScenario

        if ("section_is_support_behalf_organisation" === section) {
            scenario = value.isChecked
                ? sections.section_own_brand_promotion &&
                  sections.section_own_brand_promotion.isChecked &&
                  sections.section_is_sale_document &&
                  sections.section_is_sale_document.isChecked
                    ? "commercial"
                    : "non_profit"
                : "private"
        }

        if (scenario !== "private") {
            if ("section_own_brand_promotion" === section) {
                scenario =
                    value.isChecked && sections.section_is_sale_document && sections.section_is_sale_document.isChecked
                        ? "commercial"
                        : "non_profit"

                if (sections.section_cost && sections.section_cost.value === "1") {
                    scenario = `${scenario}_mini`
                }
            }

            if ("section_is_sale_document" === section) {
                scenario =
                    value.isChecked &&
                    sections.section_own_brand_promotion &&
                    sections.section_own_brand_promotion.isChecked
                        ? "commercial"
                        : "non_profit"

                if (sections.section_cost && sections.section_cost.value === "1") {
                    scenario = `${scenario}_mini`
                }
            }

            if ("section_cost" === section) {
                scenario =
                    sections.section_own_brand_promotion &&
                    sections.section_own_brand_promotion.isChecked &&
                    sections.section_is_sale_document &&
                    sections.section_is_sale_document.isChecked
                        ? "commercial"
                        : "non_profit"

                if (value.value === "1") {
                    scenario = `${scenario}_mini`
                }
            }
        }

        let newSections = { ...sections, [section]: value }

        if (!value) {
            newSections = Object.keys(sections)
                .filter(key => key !== section)
                .reduce((output, key) => {
                    output[key] = sections[key]
                    return output
                }, {})
        }

        this.setState(
            {
                currentScenario: scenario,
                sections: newSections
            },
            () => {
                !isValidationDisabled && this.validateSections({ section })
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.currentScenario !== this.props.currentScenario &&
            prevState.currentScenario.length !== this.state.currentScenario.length
        ) {
            this.clearStateWhenChangingScenario()
        }
    }

    state = {
        sections: {},
        errors: {},
        jumpToError: "",
        handlePostForm: this.handlePostForm,
        handleSetSectionValue: this.handleSetSectionValue,
        handleSetStepIndex: this.handleSetStepIndex,
        currentScenario: "commercial",
        isSending: false,
        stepIndex: 0
    }

    clearStateWhenChangingScenario = () => {
        const firstStep = steps[0]
        const secondStep = steps[1]
        const { sections, errors, currentScenario, stepIndex } = this.state

        this.setState({
            sections: Object.keys(sections).reduce((output, key) => {
                if (
                    (firstStep.sections.includes(key) || secondStep.sections.includes(key)) &&
                    !scenarios[currentScenario].excludedSections.includes(key)
                ) {
                    output[key] = sections[key]
                }

                return output
            }, {}),
            errors: Object.keys(errors)
                .filter(key => firstStep.sections.includes(key) || secondStep.sections.includes(key))
                .reduce((output, key) => {
                    output[key] = errors[key]
                    return output
                }, {})
        })

        this.isChangedScenario = stepIndex > 0
    }

    getContext() {
        return FormStoreContext
    }

    validateSections({ section } = {}) {
        const { sections, currentScenario, stepIndex, errors } = this.state

        const selectedScenario = scenarios[currentScenario]
        const currentStep = steps[selectedScenario.steps[stepIndex]]

        const sectionIndex = section ? currentStep.sections.findIndex(value => value === section) : null

        const emptySectionsInCurrentStep = currentStep.sections
            .filter(
                section =>
                    !selectedScenario.excludedSections.includes(section) &&
                    !VALIDATION_IGNORED_SECTIONS.includes(section)
            )
            .reduce((output, section, index) => {
                const capIndex = sectionIndex !== null ? sectionIndex : 99999

                if (!Object.keys(sections).includes(section) && index < capIndex) {
                    output.push(section)
                }

                return output
            }, [])

        const emptySectionErrors = !section
            ? emptySectionsInCurrentStep.reduce((output, section) => {
                  const message =
                      section === "section_attachment" ? MESSAGES.ADD_ATTACHMENT : MESSAGES.ANSWER_THE_QUESTION
                  output[section] = {
                      message,
                      additional: {}
                  }
                  return output
              }, {})
            : {}

        const validationErrors = {}

        currentStep.validate &&
            Object.keys(currentStep.validate).forEach(validatedSection => {
                const fieldValue = this.state.sections[validatedSection]
                if (fieldValue && validatedSection !== section) {
                    const error = currentStep.validate[validatedSection](fieldValue, sections)
                    if (error) {
                        validationErrors[validatedSection] = error
                    }
                }
            })

        const checkedOldErrors = !this.isChangedScenario
            ? Object.keys(errors).reduce((output, key) => {
                  if (!sections[key]) {
                      output[key] = errors[key]
                  }
                  return output
              }, {})
            : {}

        const newErrors = {
            ...validationErrors,
            ...checkedOldErrors,
            ...emptySectionErrors
        }

        this.isChangedScenario = false

        this.setState({
            errors: newErrors,
            jumpToError: section ? "" : jumpToFirstError(currentStep.sections, newErrors)
        })

        return Object.keys(newErrors).length === 0
    }
}

function jumpToFirstError(sections, errors) {
    for (let i = 0; i < sections.length; ++i) {
        const sectionName = sections[i]
        if (errors[sectionName]) {
            return sectionName
        }
    }

    return ""
}

export default FormStore
