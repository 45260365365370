import React, { useState, useContext } from "react"

import { Button } from "components/forms"
import { DictionariesStoreContext } from "store/DictionariesStore"

import formStatuses from "config/formStatuses"
import formEventTypes from "config/formEventTypes"

import SendOrDelete from "../SendOrDelete"

import getCxFromStyles from "helpers/getCxFromStyles"
import styles from "./index.module.css"
import ArrowDownIcon from "assets/arrow_down.module.svg"
import EmailIcon from "assets/ico-mail.module.svg"
import ContactIcon from "assets/ico-contact.module.svg"
import UserIcon from "assets/ico-user.module.svg"

const cx = getCxFromStyles(styles)

const Submission = props => {
    const {
        id,
        status,
        type,
        content,
        created_at,
        history,
        deleteSubmission,
        sendMail,
        mail_sent: mailSent,
        compatibility
    } = props

    if (!content) {
        return null
    }

    const [isExpanded, setIsExpanded] = useState(false)
    const { costs, concernAreas } = useContext(DictionariesStoreContext)

    const representant = content.section_representant
        ? content.section_representant
        : content.section_representant_private || null
    const cost = content.section_cost ? content.section_cost.value : null
    const concernArea = content.section_concern_area ? content.section_concern_area.value : null

    const isPrivate = type === "private"
    const costLabel = costs.data[cost] ? costs.data[cost].name : "-"
    const concernAreaLabel = concernAreas.data[concernArea]
        ? concernAreas.data[concernArea].name
        : content.section_concern_area.other
        ? content.section_concern_area.other
        : ""
    const eventType =
        content.section_event_type && content.section_event_type.isChecked
            ? formEventTypes.event_project
            : formEventTypes.organization
    const isRejected = status === "rejected" || status === "rejected_by_system"

    return (
        <li onClick={() => setIsExpanded(!isExpanded)} key={id} className={cx("root", { isExpanded })}>
            <ArrowDownIcon className={cx("arrow")} />

            <div className={cx("column", "name")}>
                <div className={cx("nameWrapper")}>
                    <p className={cx("nameText")}>
                        {content.section_event_type ? content.section_event_type.name : "Nazwa"}
                    </p>
                </div>
                <div>
                    {eventType} {formStatuses[status] && <span className={cx(status)}>{formStatuses[status]}</span>}
                </div>
            </div>

            <div className={cx("column", "supportForm")}>
                <span>Kwota wsparcia</span>
                {costLabel} tys. PLN
            </div>

            <div className={cx("column")}>
                <span>Kategoria</span>
                <div className={cx("concernAreaLabelWrapper")}>
                    <p className={cx("concernAreaLabel")}>{concernAreaLabel}</p>
                </div>
            </div>

            <div className={cx("column")}>
                <span>Data dodania</span>
                {created_at.date ? created_at.date.split(" ")[0] : ""}
            </div>

            <div className={cx("column")}>
                <span>Zgodność</span>
                {`${compatibility}%` || "-"}
            </div>

            <div className={cx("column", "button", { isPrivate })}>
                <div>
                    {isPrivate && (
                        <span className={cx("private")}>
                            <UserIcon />
                            Prywatne
                        </span>
                    )}
                    <Button
                        theme="white"
                        size="s-size"
                        onClick={event => {
                            event.stopPropagation()
                            history.push("/panel/zgloszenia/" + id)
                        }}
                    >
                        Szczegóły
                    </Button>
                </div>
                {isRejected && (
                    <SendOrDelete.Dropdown
                        sendMail={sendMail}
                        deleteSubmission={deleteSubmission}
                        mailSent={mailSent}
                    />
                )}
            </div>

            {representant && representant.name && (
                <div className={cx("contactInfo")}>
                    <div>
                        <b>Osoba kontaktowa:</b> {representant.name} {representant.surname}
                    </div>
                    <div>
                        <a href={`mailto:${representant.email}`} onClick={event => event.stopPropagation()}>
                            <EmailIcon /> {representant.email}
                        </a>
                        <a href={`tel:${representant.phone}`} onClick={event => event.stopPropagation()}>
                            <ContactIcon /> {representant.phone}
                        </a>
                    </div>
                </div>
            )}
        </li>
    )
}

export default Submission
