import { SubmissionsStoreContext } from "store/SubmissionsStore"
import withAbstractStoreHOC from "store/withAbstractStoreHOC"

const withSubmissions = () => {
    return WrappedComponent =>
        withAbstractStoreHOC({
            Context: SubmissionsStoreContext,
            storeKey: "submissions"
        })(WrappedComponent)
}

export default withSubmissions
