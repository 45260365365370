import React, { Component } from "react"
import idx from "idx"

import { withStyles } from "@mindz/react-hoc"
import styles from "./index.module.css"
import customSubHeaderStyles from "./overrides/SubHeader.module.css"
import buttonStyles from "./overrides/Button.module.css"

import { Button } from "components/forms"
import InfiniteScroll from "components/InfiniteScroll"
import SubHeader from "components/SubHeader"
import Skeleton from "components/Skeleton"
import SectionLoader from "components/SectionLoader"
import IconLoading from "assets/icon-loading.module.svg"
import IconExport from "assets/ico-export.module.svg"

import Tabs from "./components/Tabs"
import Filters from "./components/Filters"
import Submission from "./components/Submission"
import SendOrDelete from "./components/SendOrDelete"
import SendOrDeleteModal from "components/SendOrDeleteModal"

class SubmissionsPage extends Component {
    state = {
        sendOrDelete: {
            isOpen: false,
            type: "",
            submissionId: null
        }
    }

    render() {
        const { cx, submissions } = this.props

        return (
            <div className={cx("root")}>
                {this.renderHeader()}
                <Skeleton
                    fetchStatus={submissions.list.fetchStatus}
                    component={SectionLoader}
                    render={this.renderContent}
                />
                <SendOrDeleteModal
                    {...this.state.sendOrDelete}
                    forAll={!this.state.sendOrDelete.submissionId}
                    closeModal={this.handleCloseSendOrDeleteModal}
                    submitForm={this.handleSubmitSendOrDelete}
                />
            </div>
        )
    }

    renderHeader() {
        const {
            cx,
            submissions: {
                exportToFile,
                isExporting,
                list,
                filterParams: { status }
            }
        } = this.props

        const isEnabledSendAll = list.data.some(submission => submission.mail_sent < 1)

        return (
            <div className={cx("header")}>
                <SubHeader title="Lista zgłoszeń" customStyles={customSubHeaderStyles}>
                    <div className={cx("exportHolder")}>
                        <Button
                            size="m-size"
                            icon={isExporting ? null : IconExport}
                            onClick={() => exportToFile({ type: "xls" })}
                            isLoading={isExporting}
                            customStyles={buttonStyles}
                        >
                            EKSPORTUJ
                        </Button>
                        {status === "rejected" && (
                            <SendOrDelete.Dropdown
                                sendMail={() => this.handleOpenModal("send")}
                                deleteSubmission={() => this.handleOpenModal("delete")}
                                mailSent={!isEnabledSendAll}
                            />
                        )}
                    </div>

                    <Tabs />
                </SubHeader>
                <div className={cx("filtersRow")}>
                    <Filters />
                    {status === "rejected" && (
                        <SendOrDelete
                            sendAll={() => this.handleOpenModal("send")}
                            deleteAll={() => this.handleOpenModal("delete")}
                            isEnabledSendAll={isEnabledSendAll}
                            isEnabledDeleteAll={!!list.data.length}
                        />
                    )}
                </div>
            </div>
        )
    }

    renderContent = () => {
        const { cx, submissions, handleLoadMore, history } = this.props

        if (!idx(submissions.list, _ => _.data.length)) {
            return (
                <section className={cx("emptyList")}>
                    <h2>Brak zgłoszeń dla wybranych filtrów</h2>
                </section>
            )
        }

        return (
            <InfiniteScroll
                loadMore={handleLoadMore}
                hasMore={submissions.list.meta.current_page < submissions.list.meta.pages}
                loader={
                    <div className={cx("loader")} key={0}>
                        <IconLoading />
                        <span>Ładowanie...</span>
                    </div>
                }
            >
                <ul className={cx("list")}>
                    {submissions.list.data.map((submission, index) => {
                        return (
                            <Submission
                                {...submission}
                                key={index}
                                history={history}
                                deleteSubmission={() => this.handleOpenModal("delete", submission.id)}
                                sendMail={() => this.handleOpenModal("send", submission.id)}
                            />
                        )
                    })}
                </ul>
            </InfiniteScroll>
        )
    }

    handleOpenModal = (type, submissionId = null) =>
        this.setState({ sendOrDelete: { isOpen: true, type, submissionId } })

    handleCloseSendOrDeleteModal = () =>
        this.setState({ sendOrDelete: { isOpen: false, type: "", submissionId: null } })

    handleSubmitSendOrDelete = () => {
        const { type, submissionId } = this.state.sendOrDelete
        const { sendMessageToRejected, deleteSubmission } = this.props.submissions

        const submissions = !!submissionId ? [submissionId] : false

        if (type === "send") {
            sendMessageToRejected(submissions)
        }

        if (type === "delete") {
            deleteSubmission(submissions)
        }

        this.handleCloseSendOrDeleteModal()
    }
}

export default withStyles(styles)(SubmissionsPage)
