import React, { useContext } from "react"
import { hot } from "react-hot-loader"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { NotificationContainer } from "react-notifications"
import { AuthStoreContext } from "store/AuthStore"

// import HomePage from "pages/HomePage"
import HomePagePlatformInactive from "pages/HomePagePlatformInactive"
// import FormPage from "pages/FormPage"
// import ThanksPage from "pages/ThanksPage"
import LoginPage from "pages/LoginPage"
import ResetPasswordPage from "pages/ResetPasswordPage"
import SubmissionsPage from "pages/SubmissionsPage"
import SubmissionDetailsPage from "pages/SubmissionDetailsPage"

import Store from "store"

import "react-notifications/lib/notifications.css"

const Router = hot(module)(() => {
    const { isAdmin } = useContext(AuthStoreContext)

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={HomePagePlatformInactive} />
                {/*<Route path="/formularz" exact component={FormPage} />*/}
                {/*<Route path="/formularz/dziekujemy" component={ThanksPage} />*/}
                <Route exact path="/panel/logowanie" component={LoginPage} />
                <Route exact path="/panel/resetowanie-hasla/:token?" component={ResetPasswordPage} />
                {isAdmin && (
                    <Route
                        exact
                        path="/panel/wyloguj"
                        render={() => {
                            localStorage.clear()
                            return <Redirect to="/" />
                        }}
                    />
                )}
                {isAdmin && <Route exact path="/panel/zgloszenia" component={SubmissionsPage} />}
                {isAdmin && <Route exact path="/panel/zgloszenia/:id" component={SubmissionDetailsPage} />}
                <Route
                    path="/panel"
                    render={() => <Redirect to={isAdmin ? "/panel/zgloszenia" : "/panel/logowanie"} />}
                />
                <Route
                    path="/panel/*"
                    render={() => <Redirect to={isAdmin ? "/panel/zgloszenia" : "/panel/logowanie"} />}
                />
                <Route path="*" render={({ match }) => <p className="pageNotFound">Nie znaleziono strony</p>} />
            </Switch>
        </BrowserRouter>
    )
})

const App = () => {
    return (
        <Store>
            <Router />
            <NotificationContainer />
        </Store>
    )
}

export default App
