import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import styles from './index.module.css'

class Dialog extends Component {
    render() {
        const { cx, className, children } = this.props
        return (
            <div className={cx('dialog', className)} onClick={this.handleClick}>
                {children}
            </div>
        )
    }

    handleClick = e => {
        e.stopPropagation()
    }
}

export default withStyles(styles)(Dialog)
