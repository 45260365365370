import React from "react"

import idx from "idx"

import Table from "../Table"
import getCxFromStyles from "helpers/getCxFromStyles"
import styles from "../../index.module.css"

const cx = getCxFromStyles(styles)
const Representant = props => {
    const type = idx(props.submissions, _ => _.current.data.type)
    const sectionRepresentantLabel = `section_representant${type === "private" ? "_private" : ""}`
    const representant = idx(props.submissions, _ => _.current.data.content[sectionRepresentantLabel])
    const employee = idx(props.submissions, _ => _.current.data.content.section_is_kaufland_employee)

    return (
        <article className={cx("contact")}>
            <h2>Osoba kontaktowa</h2>
            <Table>
                <Table.Content>
                    <tr>
                        <td>Imię i nazwisko</td>
                        <td>{representant && representant.name + " " + representant.surname}</td>
                    </tr>
                    <tr>
                        <td>E-mail</td>
                        <td className={cx("table-bold")}>{representant && representant.email}</td>
                    </tr>
                    <tr>
                        <td>Telefon</td>
                        <td className={cx("table-bold")}>{representant && representant.phone}</td>
                    </tr>
                    <tr>
                        <td>Czy jesteś pracownikiem firmy Kaufland?</td>
                        <td>{employee && employee.isChecked ? "Tak - " + employee.name : "Nie"}</td>
                    </tr>
                </Table.Content>
            </Table>
        </article>
    )
}

export default Representant
