import React from "react"
import PropTypes from "prop-types"
import * as yup from "yup"
import { Formik } from "formik"
import { Modal } from "components/modal"
import { Button, InputField } from "components/forms"
import { Field } from '@mindz/react-admin-ui'
import dialogStyles from './overrides/Dialog.module.css'

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email("Niepoprawna forma adresu email")
        .required("To pole jest wymagane")
})

const RemindPasswordModal = ({ isOpen, handleClose, handleSubmit }) => {
    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <Modal.Dialog customStyles={dialogStyles}>
                <Modal.Header handleClose={handleClose}>
                    <Modal.Title>Odzyskaj hasło</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{ email: "" }}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        onSubmit={handleSubmit}
                        render={({ isSubmitting, handleSubmit, errors }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col">
                                            <Field
                                                type="text"
                                                name="email"
                                                headerLabel="Twój e-mail"
                                                placeholder=""
                                                error={errors.email}
                                                component={InputField}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Button type="button" size="m-size" theme="white" onClick={handleClose}>
                                            Anuluj
                                        </Button>
                                        <Button type="submit" size="m-size" isLoading={isSubmitting}>
                                            Dalej
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    )
}

RemindPasswordModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
}

export default RemindPasswordModal
