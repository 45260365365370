import SubmissionsIcon from "assets/ico-submissions.module.svg"

export const shortcuts = (isManufacturer, isSalesman) => {
    return [
        {
            name: "zgłoszenia",
            link: "/panel/zgloszenia",
            icon: SubmissionsIcon
        }
    ]
}
