import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import { Modal } from "components/modal"
import { Button, RadioGroup, RadioInput } from "components/forms"
import { Field } from "@mindz/react-admin-ui"
import { SUBMISSION_TYPE } from "constances"
import ChangeImage from "assets/img-change.module.svg"
import RadioGroupStyles from './overrides/RadioGroup.module.css'
import ModalStyles from './overrides/Modal.module.css'
import ButtonStyles from './overrides/Button.module.css'

const ChangeTypeModal = ({ isOpen, handleClose, handleSubmit, typeSubmission }) => {
    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <Modal.Dialog>
                <Modal.Header handleClose={handleClose} customStyles={ModalStyles}>
                    <ChangeImage />
                    <Modal.Title customStyles={ModalStyles}>
                        Zmień typ zgłoszenia
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body customStyles={ModalStyles}>
                    <p>Wybierz typ zgłoszenia na jaki chcesz zmienić:</p>
                    <Formik
                        initialValues={{ type: typeSubmission }}
                        validateOnChange={false}
                        onSubmit={handleSubmit}
                        render={({ isSubmitting, handleSubmit, errors, values }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col">
                                            <Field
                                                type="checkbox"
                                                name="type"
                                                error={errors.type}
                                                render={({ name, setFieldValue }) => {
                                                    return (
                                                        <RadioGroup
                                                            name={name}
                                                            handleChange={selectedValue => {
                                                                setFieldValue("type", selectedValue)
                                                            }}
                                                            value={values.type}
                                                        >
                                                            {Object.keys(SUBMISSION_TYPE).map(key => (
                                                                <RadioInput
                                                                    customStyles={RadioGroupStyles}
                                                                    id={key}
                                                                    value={key}
                                                                    key={key}
                                                                    text={SUBMISSION_TYPE[key].text}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="buttonsWrapper">
                                        <Button type="button" size="m-size" theme="white" onClick={handleClose} customStyles={ButtonStyles}>
                                            Anuluj
                                        </Button>
                                        <Button
                                            type="submit"
                                            size="m-size"
                                            isLoading={isSubmitting}
                                            disabled={values.type === typeSubmission}
                                            customStyles={ButtonStyles}
                                        >
                                            Dalej
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    )
}

ChangeTypeModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
}

export default ChangeTypeModal
