import React, { Component } from "react"

class Skeleton extends Component {
    state = {
        isLoading: false,
        isLoaded: false,
        isError: false,
        lastUpdate: null,
        showSkeleton: false
    }

    timeout = null

    componentDidMount() {
        const { fetchStatus, isLoading, isLoaded, isError } = this.props

        const currentTime = new Date().getTime()

        if ((fetchStatus && fetchStatus.isLoading) || isLoading) {
            this.showSkeleton()
        }

        if (fetchStatus) {
            return this.setState({
                ...fetchStatus,
                lastUpdate: currentTime
            })
        }

        this.setState({
            isLoading,
            isLoaded,
            isError,
            lastUpdate: currentTime
        })
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    componentDidUpdate(prevProps) {
        const { fetchStatus } = this.props
        const { isLoading, isLoaded, isError } = fetchStatus

        if (prevProps.fetchStatus.isLoading === isLoading) {
            return
        }

        const nextFetchStatus = fetchStatus
            ? fetchStatus
            : {
                  isLoading,
                  isLoaded,
                  isError
              }

        if (nextFetchStatus.isLoading && !this.state.isLoading) {
            this.showSkeleton()
        }

        if (!nextFetchStatus.isLoading && this.state.isLoading) {
            clearTimeout(this.timeout)
        }

        const currentTime = new Date().getTime()

        const nextState = {
            ...nextFetchStatus,
            lastUpdate: currentTime
        }

        return this.setState(prevState => ({
            ...prevState,
            ...nextState,
            showSkeleton: false
        }))
    }

    showSkeleton() {
        this.timeout = setTimeout(() => {
            this.setState({ showSkeleton: true })
        }, 10)
    }

    render() {
        const { children, render, component: SkeletonComponent } = this.props
        const { isLoading, isLoaded, showSkeleton } = this.state

        if (isLoaded) {
            if (typeof render === "function") {
                return render()
            }

            return children ? children : null
        }

        if (isLoading && showSkeleton) {
            if (SkeletonComponent) {
                if (React.isValidElement(SkeletonComponent)) {
                    return SkeletonComponent
                }

                return <SkeletonComponent {...this.props} />
            }
        }

        return null
    }
}

export default Skeleton
