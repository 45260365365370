import * as yup from "yup"

export default yup.object().shape({
    password: yup
        .string()
        .min(8, "Hasło musi zawierać co najmniej 8 znaków")
        .required("To pole jest wymagane"),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref("password")], "Powtórzone hasło nie pasuje")
        .required("To pole jest wymagane")
})
