import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import styles from './index.module.css'

class Header extends Component {
    render() {
        const { cx, children } = this.props
        return <div className={cx('header')}>{children}</div>
    }
}

export default withStyles(styles)(Header)
