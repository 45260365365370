import Modal from './Modal'
import Dialog from './Dialog'
import Header from './Header'
import Title from './Title'
import Body from './Body'
import Close from './Close'

Modal.Dialog = Dialog
Modal.Header = Header
Modal.Title = Title
Modal.Body = Body
Modal.Close = Close

export default Modal
