import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import styles from './index.module.css'
import IconClose from 'assets/close.module.svg'

class Close extends Component {
    render() {
        const { cx, size, handleClose } = this.props
        return (
            <div className={cx('close', size)} onClick={handleClose}>
                <IconClose />
            </div>
        )
    }
}

export default withStyles(styles)(Close)
