import React from "react"
import PropTypes from "prop-types"

import RemindPasswordModal from "./components/RemindPasswordModal"
import { Field, InputField, Button } from "components/forms"
import styles from "./index.module.css"
import getCxFromStyles from "helpers/getCxFromStyles"
import { Formik } from "formik"
import validationSchema from "./loginSchema"
import Logo from "assets/login-logo.module.svg"

const cx = getCxFromStyles(styles)

const LoginPage = ({
    handleSubmit,
    isRemindPasswordModalOpen,
    toggleRemindPasswordModal,
    handleSubmitRemindPassword
}) => {
    return (
        <div className={cx("root")}>
            <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                render={({ handleSubmit, isSubmitting, values, errors }) => {
                    return (
                        <form className={cx("form")} onSubmit={handleSubmit}>
                            <Logo />
                            <h6>Panel zarządzania</h6>
                            <h1>Zaloguj się do platformy</h1>
                            <Field
                                name="email"
                                headerLabel="E-mail"
                                type="text"
                                placeholder=""
                                component={InputField}
                            />
                            <Field
                                headerLabel="Hasło"
                                placeholder=""
                                name="password"
                                type="password"
                                component={InputField}
                            />
                            <Button type="submit" size="m-size" className="submit" isLoading={isSubmitting}>
                                Zaloguj się
                            </Button>

                            <div className={cx("forgotPassword")}>
                                Nie pamiętasz hasła?
                                <span className={cx("link")} onClick={toggleRemindPasswordModal}>
                                    {" "}
                                    Odzyskaj hasło
                                </span>
                            </div>
                        </form>
                    )
                }}
            />
            <RemindPasswordModal
                isOpen={isRemindPasswordModalOpen}
                handleClose={toggleRemindPasswordModal}
                handleSubmit={handleSubmitRemindPassword}
            />
        </div>
    )
}

LoginPage.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    toggleRemindPasswordModal: PropTypes.func.isRequired,
    isRemindPasswordModalOpen: PropTypes.bool.isRequired,
    handleSubmitRemindPassword: PropTypes.func.isRequired
}

export default LoginPage
