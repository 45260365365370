const sections = [
    {
        name: "Informacje o przedmiocie wsparcia",
        sections: [
            "section_concern_area",
            "section_event_date",
            "section_own_brands",
            "section_support_form",
            "section_support_range",
            "section_cost",
            "section_event_type"
        ],
        extraQuestion: {
            section_cost: "Szacowana kwota:",
            section_event_type: "Szczegóły wydarzenia lub projektu:",
            store: "Market:"
        }
    },
    {
        name: "Uczestnicy wydarzenia / projektu",
        nameOrganization: "Beneficjenci sponsoringu",
        sections: [
            "section_target_group",
            "section_target_interests",
            "section_active_contestants_number",
            "section_audience_contestants_number"
        ]
    },
    {
        name: "Sponsorzy i reklama",
        sections: [
            "section_is_mass_media",
            "section_is_outdoor",
            "section_is_competitive_sponsors",
            "section_is_outside_sponsors",
            "section_is_choose_level_involvment",
            "section_is_own_campaign"
        ],
        extraQuestion: {
            section_is_mass_media: "W których kanałach?",
            section_is_own_campaign: "Szczegóły kampanii:"
        }
    }
]

export default sections
