import { AuthStoreContext } from "store/AuthStore"
import withAbstractStoreHOC from "store/withAbstractStoreHOC"

const withAuth = () => {
    return WrappedComponent =>
        withAbstractStoreHOC({
            Context: AuthStoreContext,
            storeKey: "auth"
        })(WrappedComponent)
}

export default withAuth
