import React, { Component } from "react"

import Cookies from "components/Cookies"
import { withStyles } from "@mindz/react-hoc"
import styles from "./index.module.css"

import Logo from "assets/logo.module.svg"
import LogoGray from "assets/logo-grayscale.module.svg"
import people from "assets/people.png"
import paintMarks from "assets/paint-marks.jpg"
import photosGroup from "assets/photos-group.png"

class HomePagePlatformInactive extends Component {
    render() {
        const { cx } = this.props

        return (
            <div className={cx("root")}>
                <img src={paintMarks} alt="background" />
                <Logo />
                <section className={cx("cooperation")}>
                    <img src={photosGroup} alt="photos group" />
                    <p className={cx("title-bold")}>
                        Platforma <span className={cx("no-break")}>nieaktywna</span>
                    </p>
                    <p className={cx("title")}></p>
                    <p className={cx("description")}>
                        Działanie platformy zostało zawieszone.
                    </p>
                </section>
                <footer className={cx("footer")}>
                    <LogoGray />
                    <img src={people} alt="people" />
                    <div className={cx("separator")} />
                    <span>
                        <p>© 2023 Kaufland Polska Markety Spółka z ograniczoną odpowiedzialnością&nbsp;sp.&nbsp;j.</p>
                        <a target="_blank" rel="noopener noreferrer" href="/regulamin.pdf">
                            <p>Regulamin serwisu</p>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.kaufland.pl/polityka-prywatnosci.html">
                            <p>Polityka prywatności</p>
                        </a>
                    </span>
                </footer>
                <Cookies />
            </div>
        )
    }
}

export default withStyles(styles)(HomePagePlatformInactive)
