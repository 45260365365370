import React, { Component } from 'react'

import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'

class Content extends Component {
    render() {
        const { cx, children } = this.props

        return (
            <tbody className={cx('root')}>
                {children}
            </tbody>
        )
    }
}

export default withStyles(styles)(Content)
