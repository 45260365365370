import React from "react"
import { Dropdown } from "@mindz/react-admin-ui"
import IndicatorDefault from "./IndicatorDefault"

import styles from "./index.module.css"

const DropdownMenu = props => (
    <Dropdown {...props} customStyles={styles} IndicatorComponent={props.IndicatorComponent || IndicatorDefault}>
        {props.children}
    </Dropdown>
)

export default DropdownMenu
