import React, { Component } from "react"

import withSubmissions from "HOC/withSubmissions"

import AdminLayout from "layouts/AdminLayout"
import SubmissionDetailsPage from "./SubmissionDetailsPage"

class SubmissionDetailsPageContainer extends Component {

    componentDidMount() {
        const {
            match: {
                params: { id }
            },
            submissions: { fetchCurrentSubmission, fetchSubmissions }
        } = this.props

        fetchSubmissions()
        fetchCurrentSubmission(id)

        window.scrollTo(0, 0)
    }

    render() {
        return (
            <AdminLayout>
                <SubmissionDetailsPage {...this.props} handleChangeType={this.handleChangeType}/>
            </AdminLayout>
        )
    }

    handleChangeType = (values) => {
        const {
            match: {
                params: { id }
            },
            submissions: { patchSubmissionType },
            history: { push }
        } = this.props

        patchSubmissionType(id, values.type)
        .then(() => {
            push('/panel/zgloszenia')
        })
    }


}

export default withSubmissions()(SubmissionDetailsPageContainer)
