import React, { Component } from "react"
import ResetPasswordPage from "./ResetPasswordPage"
import fetch from "helpers/fetch"
import { NotificationManager } from "react-notifications"
import { Redirect } from "react-router-dom"
import { MESSAGES } from "constances"

class ResetPasswordPageContainer extends Component {
    getToken() {
        return this.props.match.params.token
    }

    render() {
        if (!this.getToken()) {
            return <Redirect to="/panel/logowanie" />
        }

        return <ResetPasswordPage handleSubmit={this.handleSubmit} />
    }

    handleSubmit = ({ password }, formikActions) => {
        fetch
            .postRAW("/reset-password", { password, token: this.getToken() })
            .then(() => {
                NotificationManager.success(
                    "Możesz się zalogować używając nowego hasła",
                    MESSAGES.NOTIFICATION_SUCCESS_HEADER
                )
                this.props.history.replace("/panel/logowanie")
            })
            .catch(err => {
                formikActions.setSubmitting(false)
                NotificationManager.error(
                    "Nie udało się zresetować hasła. W razie problemów skontaktuj się z administratorem serwisu.",
                    MESSAGES.NOTIFICATION_ERROR_HEADER
                )
            })
    }
}

export default ResetPasswordPageContainer
