import React from "react"
import { Textarea as TextareaBase } from "@mindz/react-admin-ui"

import { withStyles } from "@mindz/react-hoc"

import styles from "./index.module.css"

export default withStyles(styles)(({ children, ...props }) => (
    <TextareaBase.Kaufland {...props}>{children}</TextareaBase.Kaufland>
))
