import React from "react"

import idx from "idx"
import AttachmentIcon from "assets/attachment.module.svg"

import getCxFromStyles from "helpers/getCxFromStyles"
import styles from "../../index.module.css"

const cx = getCxFromStyles(styles)

const Attachment = props => {
    const attachment = idx(props.submissions, _ => _.current.data.content.section_attachment)

    if (!attachment) return null

    return (
        <div className={cx("section", "attachment")}>
            <p>Szczegółowa oferta dla firmy Kaufland:</p>
            <a href={props.submissions.current.data.attachment_path} target="blank">
                <AttachmentIcon />
                <p>Pokaż załącznik</p>
            </a>
        </div>
    )
}

export default Attachment
