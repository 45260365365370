import React, { Component } from "react"
import { Link } from "react-router-dom"
import { withStyles } from "@mindz/react-hoc"
import idx from "idx"

import styles from "./index.module.css"

import BurgerIcon from "assets/burger.module.svg"
import ArrowDownIcon from "assets/arrow_down.module.svg"
import ArrowLeftIcon from "assets/arrow_left.module.svg"
import LogoutIcon from "assets/logout.module.svg"
import Logo from "assets/logo.module.svg"

class Header extends Component {
    render() {
        const {
            cx,
            auth: { user },
            shortcuts,
            isBusiness,
            isAdmin,
            isShownDropdown,
            visibleNotifications,
            active,
            toggleDropdown,
            setActiveShortcut,
            handleLogout,
            refRoot,
            isDocumentScrolled
        } = this.props

        return (
            <div
                className={cx("root", {
                    shown: isShownDropdown,
                    visibleNotifications,
                    shadow: isDocumentScrolled
                })}
                ref={refRoot}
            >
                <div className={cx("burger")} onClick={toggleDropdown}>
                    <BurgerIcon />
                </div>

                <Link to="/panel/zgloszenia" className={cx("logoLink")}>
                    <Logo />
                </Link>
                <div className={cx("shortcuts")}>
                    {shortcuts.map((shortcut, index) => {
                        const Icon = shortcut.icon
                        return (
                            <Link
                                className={cx({
                                    active:
                                        (shortcut.link === active &&
                                            window.location.pathname.split("/").length === 3) ||
                                        ("/zgloszenia" === shortcut.link && !(isBusiness || isAdmin) && active === "/")
                                })}
                                key={index}
                                to={shortcut.link}
                                onClick={() => setActiveShortcut(shortcut.link)}
                            >
                                <Icon />
                                {shortcut.name}
                            </Link>
                        )
                    })}
                </div>
                <div className={cx("user")}>
                    <div className={cx("profile")} onClick={toggleDropdown}>
                        <div className={cx("return")}>
                            <ArrowLeftIcon className={cx("arrowBack")} /> Wróć
                        </div>
                        <div className={cx("details")}>
                            <div className={cx("photo")}>{this.renderInitial()}</div>
                            <ul>
                                <li className={cx("name")}>{user.data.name}</li>
                                <li className={cx("type")}>{this.renderUserDescription()}</li>
                            </ul>
                        </div>
                        <ArrowDownIcon className={cx("arrow")} />
                        <div className={cx("dropdown")}>
                            <div className={cx("option")} onClick={handleLogout}>
                                <LogoutIcon /> Wyloguj
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderUserDescription() {
        return "Kaufland"
    }

    renderInitial() {
        const {
            auth: { user }
        } = this.props

        const name = idx(user, _ => _.data.name)

        let initial = ""

        if (name) {
            initial += name[0]
        }

        return <span>{initial}</span>
    }
}

export default withStyles(styles)(Header)
