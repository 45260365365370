const statuses = {
    new: "Oczekujące",
    in_progress: "Weryfikowane",
    accepted: "Zaakceptowane",
    rejected: "Odrzucone",
    rejected_by_system: "Odrzucone (auto)",
    all: "Wszystkie"
}

export default statuses
