import React from "react"
import PropTypes from "prop-types"

import { withStyles } from "@mindz/react-hoc"
import { Modal } from "components/modal"
import Button from "components/forms/Button"

import SendImg from "assets/img-send-info.module.svg"
import DeleteImg from "assets/img-delete.module.svg"

import styles from "./overrides/SendOrDeleteModal.module.css"

const titles = {
    one: {
        send: "Wiadomość o odrzuceniu zgłoszenia",
        delete: "Usuń zgłoszenie"
    },
    all: {
        send: "Wiadomość o odrzuceniu zgłoszenia",
        delete: "Usuń wszystkie zgłoszenia"
    }
}

const texts = {
    one: {
        send: "Czy na pewno chcesz wysłać wiadomość z informacją o odrzuceniu zgłoszenia?",
        delete: "Czy na pewno chcesz usunąć to złgoszenie?"
    },
    all: {
        send:
            "Czy na pewno chcesz wysłać wiadmość do wszystkich odrzuconych zgłoszeń z informacją o odrzuceniu zgłoszenia?",
        delete: "Czy na pewno chcesz usunąć wszystkie złgoszenia?"
    }
}

const SendOrDeleteModal = ({ cx, isOpen, type, forAll, closeModal, submitForm }) => {
    const handleSubmit = event => {
        event.preventDefault()
        submitForm()
    }
    return (
        <Modal isOpen={isOpen} handleClose={closeModal}>
            <Modal.Dialog className={cx("root")}>
                <Modal.Header>
                    <div className={cx("header")}>
                        {type === "send" ? <SendImg /> : <DeleteImg />}
                        <Modal.Title>{forAll ? titles.all[type] : titles.one[type]}</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form className={cx("form")} onSubmit={handleSubmit}>
                        <p>{forAll ? texts.all[type] : texts.one[type]}</p>
                        <div className={cx("buttons")}>
                            <Button size="m-size" theme="white" type="button" onClick={closeModal}>
                                Anuluj
                            </Button>
                            <Button size="m-size" type="submit">
                                Potwierdzam
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    )
}

SendOrDeleteModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    type: PropTypes.string,
    submissionId: PropTypes.number,
    closeModal: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired
}

export default withStyles(styles)(SendOrDeleteModal)
