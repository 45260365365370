import React from "react"

import AbstractStore from "./AbstractStore"
import fetch from "helpers/fetch"
import { fetchStatusHelpers } from "@mindz/react-helpers"
const { initialState, requestState, successState, failureState } = fetchStatusHelpers

export const DictionariesStoreContext = React.createContext()

class DictionariesStore extends AbstractStore {
    componentDidMount() {
        this.getDictionaries()
    }

    state = {
        submissionType: this.wrapRequestState({ type: "initial", data: {} }),
        concernAreas: this.wrapRequestState({ type: "initial", data: {} }),
        costs: this.wrapRequestState({ type: "initial", data: {} }),
        ownBrands: this.wrapRequestState({ type: "initial", data: {} }),
        targetGroups: this.wrapRequestState({ type: "initial", data: {} }),
        targetInterests: this.wrapRequestState({ type: "initial", data: {} }),
        contestantsNumber: this.wrapRequestState({ type: "initial", data: {} }),
        massMedia: this.wrapRequestState({ type: "initial", data: {} }),
        estimatedRanges: this.wrapRequestState({ type: "initial", data: {} }),
        competitiveSponsors: this.wrapRequestState({ type: "initial", data: {} }),
        audienceContestantsNumber: this.wrapRequestState({ type: "initial", data: {} }),
        supportRange: this.wrapRequestState({ type: "initial", data: {} }),
        supportForms: this.wrapRequestState({ type: "initial", data: {} }),
        titles: this.wrapRequestState({ type: "initial", data: {} }),
        stores: this.wrapRequestState({ type: "initial", data: {} }),
        beneficiaries: this.wrapRequestState({ type: "initial", data: {} }),
        mediaLevels: this.wrapRequestState({ type: "initial", data: {} }),
        fetchStatus: initialState()
    }

    getContext() {
        return DictionariesStoreContext
    }

    getDictionaries = () => {
        const dictionaries = [
            "concernAreas",
            "costs",
            "ownBrands",
            "targetGroups",
            "targetInterests",
            "contestantsNumber",
            "audienceContestantsNumber",
            "massMedia",
            "estimatedRanges",
            "supportRange",
            "supportForms",
            "competitiveSponsors",
            "titles",
            "stores",
            "beneficiaries",
            "mediaLevels"
        ]

        this.setState({ fetchStatus: requestState() }, () => {
            Promise.all(dictionaries.map(dictionary => this.fetchDictionary(dictionary)))
                .then(values => {
                    values.map((dictionary, index) => {
                        return this.saveData({
                            key: dictionaries[index],
                            data: dictionary.data || {},
                            type: "success"
                        })
                    })

                    this.setState({ fetchStatus: successState() })
                })
                .catch(err => {
                    this.setState({ fetchStatus: failureState() })
                })
        })
    }

    fetchDictionary = async dictionary => {
        await this.saveData({ key: dictionary, data: {}, type: "request" })

        return fetch.get(`/dictionaries/${dictionary}`)
    }
}

export default DictionariesStore
