import React from "react"
import { Checkbox } from "@mindz/react-admin-ui"

import { withStyles } from "@mindz/react-hoc"

import styles from "./index.module.css"

export const CheckboxCard = Checkbox.KauflandCard

export default withStyles(styles)(({ children, ...props }) => (
    <Checkbox.Kaufland {...props}>{children}</Checkbox.Kaufland>
))
