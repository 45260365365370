import React, { Component } from 'react'

import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'

class Header extends Component {
    render() {
        const { cx, children } = this.props

        return (
            <thead className={cx('root')}>
                <tr>
                    {children}
                </tr>
            </thead>
        )
    }
}

export default withStyles(styles)(Header)
