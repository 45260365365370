import { DictionariesStoreContext } from "store/DictionariesStore"
import withAbstractStoreHOC from "store/withAbstractStoreHOC"

const withDictionaries = () => {
    return WrappedComponent =>
        withAbstractStoreHOC({
            Context: DictionariesStoreContext,
            storeKey: "dictionaries"
        })(WrappedComponent)
}

export default withDictionaries
