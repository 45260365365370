import React, { Component } from "react"

import AuthStore from "./AuthStore"
import DictionariesStore from "./DictionariesStore"
import SubmissionsStore from "./SubmissionsStore"
import FormStore from "./FormStore"

class Store extends Component {
    render() {
        const { children } = this.props
        return (
            <AuthStore>
                <DictionariesStore>
                    <SubmissionsStore>
                        <FormStore>{children}</FormStore>
                    </SubmissionsStore>
                </DictionariesStore>
            </AuthStore>
        )
    }
}

export default Store
