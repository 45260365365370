import React, { Component } from "react"
import LoginPage from "./LoginPage"
import { AuthStoreContext } from "store/AuthStore"
import fetch from "helpers/fetch"
import { NotificationManager } from "react-notifications"
import { MESSAGES } from "constances"

class LoginPageContainer extends Component {
    static contextType = AuthStoreContext

    state = {
        isRemindPasswordModalOpen: false
    }

    render() {
        const { isRemindPasswordModalOpen } = this.state

        return (
            <LoginPage
                {...this.props}
                handleSubmit={this.handleSubmit}
                isRemindPasswordModalOpen={isRemindPasswordModalOpen}
                toggleRemindPasswordModal={this.toggleRemindPasswordModal}
                handleSubmitRemindPassword={this.handleSubmitRemindPassword}
            />
        )
    }

    toggleRemindPasswordModal = () => {
        this.setState(prevState => ({
            isRemindPasswordModalOpen: !prevState.isRemindPasswordModalOpen
        }))
    }

    handleSubmit = (values, formikActions) => {
        this.context
            .login(values)
            .then(() => {
                window.location.href = "/panel/zgloszenia"
            })
            .catch(json => {
                formikActions.setSubmitting(false)
                if (json.errors) {
                    formikActions.setErrors(json.errors)
                }
            })
    }

    handleSubmitRemindPassword = ({ email }, formikActions) => {
        fetch
            .postRAW("/remind-password", { email })
            .then(() => {
                this.setState({ isRemindPasswordModalOpen: false })
                NotificationManager.success(
                    `Na adres ${email} wysłano dalsze instrukcje w celu resetowania hasła.`,
                    MESSAGES.NOTIFICATION_SUCCESS_HEADER
                )
            })
            .catch(err => {
                this.setState({ isRemindPasswordModalOpen: false })
                NotificationManager.error(
                    "Nie udało się wysłać wiadomości na podany adres email.",
                    MESSAGES.NOTIFICATION_ERROR_HEADER
                )
            })
    }
}

export default LoginPageContainer
