import React, { Component } from "react"

import { withStyles } from "@mindz/react-hoc"
import withSubmissions from "HOC/withSubmissions"

import styles from "./index.module.css"

import AdminLayout from "layouts/AdminLayout"
import SubmissionsPage from "./SubmissionsPage"

class SubmissionsPageContainer extends Component {
    componentDidMount() {
        const { submissions } = this.props
        submissions.fetchSubmissions()
    }

    render() {
        return (
            <AdminLayout>
                <SubmissionsPage handleLoadMore={this.handleLoadMore} {...this.props} />
            </AdminLayout>
        )
    }

    handleLoadMore = () => {
        const {
            submissions: {
                list: {
                    meta: { pages, current_page }
                },
                setFilterParam,
                filterParams: { page }
            }
        } = this.props

        if (current_page < pages && current_page === page) {
            setFilterParam({ name: "page", value: current_page + 1 })
        }
    }
}

export default withSubmissions()(withStyles(styles)(SubmissionsPageContainer))
