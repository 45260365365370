import React, { Component } from "react"
import { AuthStoreContext } from "store/AuthStore"

import Header from "./Header"
import { shortcuts } from "./config"
import withAuth from "HOC/withAuth"

class HeaderContainer extends Component {
    static contextType = AuthStoreContext

    node = null
    refRoot = node => (this.node = node)

    constructor(props) {
        super(props)

        this.state = {
            isShownDropdown: false,
            visibleNotifications: false,
            isDocumentScrolled: false,
            active: ""
        }

        this.shortcuts = shortcuts(props.isManufacturer, props.isSalesman)
    }

    componentDidMount() {
        this.setState({
            active: `/${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]}`
        })

        window.addEventListener("click", this.handleOutsideClick, false)
        window.addEventListener("scroll", this.handleScrollWindow)
    }

    static getDerivedStateFromProps() {
        return { active: `/${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]}` }
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.handleOutsideClick, false)
        window.removeEventListener("scroll", this.handleScrollWindow)

        document.body.classList.remove("bodyOverflowHiddenForMobile")
    }

    render() {
        return (
            <Header
                {...this.props}
                {...this.state}
                shortcuts={this.shortcuts}
                toggleNotifications={this.toggleNotifications}
                toggleDropdown={this.toggleDropdown}
                setActiveShortcut={this.setActiveShortcut}
                handleLogout={this.handleLogout}
                refRoot={this.refRoot}
            />
        )
    }

    toggleNotifications = () => {
        this.setState(
            prevState => ({
                isShownDropdown: false,
                visibleNotifications: !prevState.visibleNotifications
            }),
            () => {
                const { visibleNotifications } = this.state
                const bodyClassList = document.body.classList

                if (visibleNotifications) {
                    return bodyClassList.add("bodyOverflowHiddenForMobile")
                }

                bodyClassList.remove("bodyOverflowHiddenForMobile")
            }
        )
    }

    toggleDropdown = () => {
        this.setState(prevState => ({
            isShownDropdown: !prevState.isShownDropdown,
            visibleNotifications: false
        }))
    }

    setActiveShortcut = active => {
        this.setState({ active })
    }

    handleOutsideClick = event => {
        if (this.node && !this.node.contains(event.target)) {
            this.setState({
                isShownDropdown: false,
                visibleNotifications: false
            })
        }
    }

    handleScrollWindow = event => {
        const { isDocumentScrolled } = this.state;
        const documentPosition = event.target.documentElement.scrollTop;

        if (documentPosition > 0 && !isDocumentScrolled) {
            this.setState({ isDocumentScrolled: true })
        }
        if (documentPosition === 0 && isDocumentScrolled) {
            this.setState({ isDocumentScrolled: false })
        }
    }

    handleLogout = () => {
        this.context
        .logout()
        .then(() => {
            window.location.href = "/panel/logowanie"
        })
    }
}

export default withAuth()(HeaderContainer)
