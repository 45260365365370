import React from "react"
import PropTypes from "prop-types"

import getCxFromStyles from "helpers/getCxFromStyles"
import styles from "./index.module.css"

import { Button } from "components/forms"
import MailIcon from "assets/ico-mail.module.svg"
import TrashIcon from "assets/ico-trash.module.svg"

const cx = getCxFromStyles(styles)

const SendOrDelete = ({ sendAll, deleteAll, isEnabledSendAll, isEnabledDeleteAll }) => (
    <div className={cx("root")}>
        <Button
            onClick={sendAll}
            theme="white"
            size="s-size"
            className={cx("button")}
            icon={MailIcon}
            disabled={!isEnabledSendAll}
        >
            Wiadomość do wszystkich
        </Button>
        <Button
            onClick={deleteAll}
            theme="white"
            size="s-size"
            className={cx("button")}
            icon={TrashIcon}
            disabled={!isEnabledDeleteAll}
        >
            Usuń wszystkie
        </Button>
    </div>
)

SendOrDelete.propTypes = {
    sendAll: PropTypes.func.isRequired,
    deleteAll: PropTypes.func.isRequired
}

export default SendOrDelete
