import React, { Component } from "react"
import { fetchStatusHelpers } from "@mindz/react-helpers"
import _ from "lodash"
const { successState, failureState, requestState, initialState } = fetchStatusHelpers

const requestStates = {
    success: successState,
    failure: failureState,
    request: requestState,
    initial: initialState
}

class AbstractStore extends Component {
    state = {}

    getContext() {
        return React.createContext()
    }

    wrapRequestState = ({ type, data, meta = {} }) => {
        return {
            fetchStatus: requestStates && requestStates[type] ? requestStates[type]() : initialState(),
            data,
            meta
        }
    }

    saveData = ({ key, data, type, meta = {} }) => {
        let newState = { ...this.state }
        _.set(newState, key, this.wrapRequestState({ type, data, meta }))

        return new Promise(resolve => {
            this.setState(newState, () => {
                resolve()
            })
        })
    }

    render() {
        const { children } = this.props

        const Context = this.getContext()
        return <Context.Provider value={this.state}>{children}</Context.Provider>
    }
}

export default AbstractStore
