import React from "react"

import idx from "idx"
import moment from "moment"

import { OTHER_OPTION_VALUE } from "constances"

import getCxFromStyles from "helpers/getCxFromStyles"
import styles from "../../index.module.css"
import Export from "assets/export.module.svg"
import Gift from "assets/gift.module.svg"

import { Button } from "components/forms"

const cx = getCxFromStyles(styles)

const ExportToPdf = props => {
    const costValue = idx(props.submissions, _ => _.current.data.content.section_cost.value)
    const createdAt = idx(props.submissions, _ => _.current.data.created_at.date)
    const category = idx(props.submissions, _ => _.current.data.content.section_concern_area)
    const categoryKey = category && category.value
    const categoryValue = category && category.other

    const { costs, concernAreas } = props.dictionaries

    const concernAreasDictionary = {
        ...concernAreas.data,
        ...{
            [OTHER_OPTION_VALUE]: { name: categoryValue }
        }
    }

    return (
        <div className={cx("section", "pdf")}>
            <div className={cx("gift")}>
                <Gift />
                <div>
                    <p>Kwota wsparcia:</p>
                    <p>{costs.data[costValue] ? costs.data[costValue].name : "-"} tys. zł</p>
                </div>
            </div>
            <div className={cx("info")}>
                <p>Kategoria:</p>
                <p>{concernAreasDictionary[categoryKey] ? concernAreasDictionary[categoryKey].name : "-"}</p>
            </div>
            <div className={cx("info")}>
                <p>Data dodania:</p>
                <p>{moment(createdAt).format("YYYY/MM/DD")}</p>
            </div>
            <Button
                icon={Export}
                size="s-size"
                onClick={() => props.submissions.exportToFile({ type: "pdf", id: props.match.params.id })}
            >
                Pobierz jako PDF
            </Button>
        </div>
    )
}

export default ExportToPdf
