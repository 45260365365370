import React, { useContext } from "react"

import getCxFromStyles from "helpers/getCxFromStyles"
import styles from "./index.module.css"
import formStatuses from "config/formStatuses"

import { SubmissionsStoreContext } from "store/SubmissionsStore"

const cx = getCxFromStyles(styles)

const Tabs = props => {
    const {
        setFilterParam,
        filterParams: { status },
        list: { meta: { statusesCount = {} } }
    } = useContext(SubmissionsStoreContext)

    return (
        <div className={cx("root")}>
            <div className={cx("scroll")}>
                <ul>
                    {formStatuses &&
                        Object.keys(formStatuses)
                            .filter(value => value !== "rejected_by_system")
                            .map((key, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={cx({ isActive: status === key || (!status && key === "all") })}
                                        onClick={() => {
                                            setFilterParam({ name: "status", value: key !== "all" ? key : null })
                                        }}
                                    >
                                        <span>{formStatuses[key]} <span className={cx('count')}>({statusesCount[key] || 0})</span></span>
                                    </li>
                                )
                            })}
                </ul>
            </div>
        </div>
    )
}

export default Tabs
