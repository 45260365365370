import React, { Component } from 'react'

class RootModal extends Component {
    componentDidMount() {
        document.body.classList.add('modal-open')
        document.addEventListener('keydown', this.handleKeyDown)
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-open')
        document.removeEventListener('keydown', this.handleKeyDown)
    }

    render() {
        const { handleClose, children } = this.props

        return <div onClick={handleClose}>{children}</div>
    }

    handleKeyDown = e => {
        const { handleClose } = this.props

        if (e.keyCode === 27) {
            handleClose()
        }
    }
}

export default RootModal
