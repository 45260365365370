import React, { Component } from 'react';

import styles from './Cookies.module.css';
import { Button, Checkbox } from 'components/forms';

class Cookies extends Component {

    constructor(props) {
        super(props);

        this.state = this.getDefaultState();

        let stateFromLocalStorage = this.restoreFromLocalStorage();
        if (stateFromLocalStorage !== null && stateFromLocalStorage.closed !== undefined) {
            this.state = stateFromLocalStorage;
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleCloseAndSaveDefaults = this.handleCloseAndSaveDefaults.bind(this);
        this.toggleOptions = this.toggleOptions.bind(this);
        this.elementToScroll = React.createRef();
    }

    getComponentId() {
        return 'acceptCookies';
    }

    render() {
        const { closed, isConfigurationExpanded, statisticsValue } = this.state;

        if (closed) {
            return null;
        }

        return (
            <div className={styles.cookieModalWrapper}>
                <div className={styles.cookieModal}  >
                    <h2>Zanim przejdziesz dalej: kilka słów o przetwarzaniu Twoich danych</h2>
                    <p>
                        Na <a href="https://www.kaufland.pl/polityka-prywatnosci.html" target="_blank" rel="noopener noreferrer">naszej</a> stronie internetowej, o ile jest to niezbędne do jej wyświetlenia i korzystania z jej poszczególnych funkcjonalności, przetwarzamy Państwa dane z wykorzystaniem cookies i innych podobnych technologii.
                        Jeżeli wyrażą Państwo zgodę na śledzenie, Państwa dane będą przetwarzane w celu dopasowania ustawień strony internetowej, tworzenia spseudonimizowanych statystyk lub wyświetlania spersonalizowanych treści (reklamowych).
                        Dotyczy to również przekazywania danych do państw trzecich. Wybierając „Odrzuć“ zezwalają Państwo tylko na przetwarzanie, które jest technicznie niezbędne, natomiast wybierając „Dostosuj” mają Państwo możliwość zezwolenia na indywidualne cele przetwarzania.
                        Więcej informacji, w tym o prawie do wniesienia sprzeciwu, znajdą Państwo w naszej <a href="https://www.kaufland.pl/polityka-prywatnosci.html" target="_blank" rel="noopener noreferrer">Polityce Prywatności</a>. Więcej informacji o nas znajdziesz <a href="https://www.kaufland.pl/stopka-redakcyjna.html" target="_blank" rel="noopener noreferrer">tutaj</a>.
                    
                    </p>
                    <div className={styles.cookieModalActions}>
                        <Button
                            type="button"
                            size="s-size"
                            theme="white"
                            onClick={this.handleCloseAndSaveDefaults}
                        >
                            Odrzuć
                        </Button>
                        <Button
                            type="button"
                            theme="white"
                            size="s-size"
                            onClick={this.toggleOptions}
                        >
                            {isConfigurationExpanded ? "Ukryj" : "Pokaż"} szczegóły
                        </Button>
                        <Button
                            type="button"
                            size="s-size"
                            onClick={this.handleClose}
                            disabled={isConfigurationExpanded}
                        >
                            Akceptuję
                        </Button>
                    </div>
                    {isConfigurationExpanded && (
                        <div >
                            <div className={styles.cookieModalConfiguration} >
                                <Checkbox
                                    id="necessary"
                                    name="necessary"
                                    value={true}
                                    disabled={true}
                                >
                                    Niezbędne
                                </Checkbox>
                                <Checkbox
                                    id="statistics"
                                    name="statistics"
                                    value={statisticsValue}
                                    handleChange={() => this.setState((state) => ({
                                        statisticsValue: !state.statisticsValue
                                    }))}
                                >
                                    Statystyki
                                </Checkbox>
                            </div>
                            <div className={styles.buttonWrapper} ref={this.elementToScroll}>
                                <Button
                                    type="button"
                                    size="s-size"
                                    onClick={this.handleClose}
                                >
                                    Zaakceptuj wybrane opcje
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    toggleOptions() {
        this.setState((state) => ({
            isConfigurationExpanded: !state.isConfigurationExpanded
        }))

        setTimeout(() => {
            const elementToScroll = this.elementToScroll.current
            elementToScroll && elementToScroll.scrollIntoView({behavior: 'smooth', block: 'start'});
         }, 100)
    }

    handleClose() {
        window['ga-disable'] = this.state.statisticsValue;

        this.setState({
            closed: true,
            statisticsValue: this.state.statisticsValue
        }, function () {
            this.persistToLocalStorage();
        });
    }

    handleCloseAndSaveDefaults() {
        window['ga-disable'] = false;

        this.setState({
            closed: true,
            statisticsValue: false
        }, function () {
            this.persistToLocalStorage();
        });
    }

    getDefaultState() {
        return {
            'closed': false,
            'statisticsValue': false,
            'isConfigurationExpanded': false
        }
    }

    restoreFromLocalStorage() {
        if (this.checkLocalStorage()) {
            let value = this.getLocalStorage().getItem(this.getComponentId());
            if (value !== undefined && value !== null) {
                return JSON.parse(value);
            }
        }
        return null;
    }

    persistToLocalStorage() {
        const { closed, statisticsValue } = this.state
        if (this.checkLocalStorage()) {
            this.getLocalStorage().setItem(this.getComponentId(), JSON.stringify({ closed, disableStatistics: !statisticsValue }));
        }
    }


    checkLocalStorage() {
        return this.getLocalStorage() !== undefined;
    }

    getLocalStorage() {
        return window.localStorage;
    }
}
export default Cookies;