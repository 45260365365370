import React, { Component } from "react"
import { Link } from "react-router-dom"
import idx from "idx"

import withDictionaries from "HOC/withDictionaries"
import { OTHER_OPTION_VALUE, SEPARATED_CHECKBOX_VALUE, TARGET_INTERESTS_WITH_DESCRIPTION } from "constances"
import trimMonthWord from "helpers/dateFormat"

import config from "config/submissionDetails"
import eventTypesConfig from "config/formEventTypes"

import ExportToPdf from "./components/ExportToPdf"
import Representant from "./components/Representant"
import Address from "./components/Address"
import Attachment from "./components/Attachment"
import ChangeTypeModal from "./components/ChangeTypeModal"

import { withStyles } from "@mindz/react-hoc"
import styles from "./index.module.css"
import ArrowIcon from "assets/arrow_left.module.svg"

import StatusBox from "./components/StatusBox"
import Skeleton from "components/Skeleton"
import SectionLoader from "components/SectionLoader"

class SubmissionDetailsPage extends Component {
    state = {
        isOpenChangeTypeModal: false
    }

    render() {
        const {
            cx,
            submissions: { current },
            handleChangeType
        } = this.props
        const { isOpenChangeTypeModal } = this.state
        const type = idx(current, _ => _.data.type)

        return (
            <Skeleton fetchStatus={current.fetchStatus} component={SectionLoader}>
                <div className={cx("root")}>
                    {this.renderContent()}
                    {current.data.id && (
                        <StatusBox
                            currentSubmissionData={current.data}
                            handleOpenChangeTypeModal={this.toggleOpenChangeTypeModal}
                        />
                    )}
                </div>
                <ChangeTypeModal
                    isOpen={isOpenChangeTypeModal}
                    handleClose={this.toggleOpenChangeTypeModal}
                    handleSubmit={handleChangeType}
                    typeSubmission={type}
                />
            </Skeleton>
        )
    }

    renderContent = () => {
        const { cx, submissions } = this.props
        const type = idx(submissions, _ => _.current.data.type)

        return (
            <div className={cx("content")}>
                <Link to="/panel/zgloszenia" className={cx("goBack")}>
                    <ArrowIcon />
                    <span>Wróć do zgłoszeń</span>
                </Link>
                {this.renderTitle()}
                <section className={cx("holder")}>
                    <ExportToPdf {...this.props} />
                    <Representant {...this.props} />
                    {this.renderSections()}

                    {type !== "private" && <Address {...this.props} />}
                    <Attachment {...this.props} />
                </section>
            </div>
        )
    }

    renderSections() {
        const { cx, submissions, dictionaries } = this.props
        const sectionsTitles = idx(dictionaries, _ => _.titles.data)
        const content = idx(submissions, _ => _.current.data.content)
        const isProject = idx(submissions, _ => _.current.data.content.section_event_type.isChecked)

        const sectionsRenderers = {
            section_concern_area: (key, content) => this.renderDropdownValue(key, content, "concernAreas"),
            section_cost: (key, content) => this.renderDropdownValue(key, content, "costs", " tys. zł"),
            section_audience_contestants_number: (key, content) =>
                this.renderDropdownValue(key, content, "contestantsNumber"),
            section_active_contestants_number: (key, content) =>
                this.renderDropdownValue(key, content, "contestantsNumber"),
            section_support_range: (key, content) => this.renderDropdownValue(key, content, "supportRange"),

            section_own_brands: (key, content) => this.renderCheckboxes(key, content, "ownBrands"),
            section_is_mass_media: (key, content) => this.renderCheckboxes(key, content, "massMedia"),
            section_is_mass_media_organization: (key, content) => this.renderCheckboxes(key, content, "massMedia"),
            section_is_competitive_sponsors: (key, content) =>
                this.renderCheckboxes(key, content, "competitiveSponsors"),
            section_is_outside_sponsors: (key, content) => this.renderCheckboxes(key, content, "competitiveSponsors"),
            section_target_group: (key, content) => this.renderCheckboxes(key, content, "targetGroups"),
            section_target_group_organization: (key, content) => this.renderCheckboxes(key, content, "targetGroups"),
            section_target_interests: (key, content) => this.renderCheckboxes(key, content, "targetInterests"),
            section_target_interests_organization: (key, content) =>
                this.renderCheckboxes(key, content, "targetInterests"),

            section_is_outdoor: (key, content) => this.renderBinaryState(key, content),
            section_is_own_campaign: (key, content) => this.renderBinaryState(key, content),
            section_is_choose_level_involvment: (key, content) => this.renderBinaryState(key, content),

            section_support_form: (key, content) => this.renderSupportForm(key, content),
            section_event_type: (key, content) => {
                const eventType = content[key] ? content[key] : null
                return [
                    eventType && eventType.isChecked ? eventTypesConfig.event_project : eventTypesConfig.organization
                ]
            },

            section_event_date: this.renderRange,
            default: this.renderDefault
        }

        const extraRenderers = {
            section_is_mass_media: (key, content) => {
                if (content[key] && !!content[key].isChecked) {
                    return this.renderExtra(key, content, "massMedia", "estimatedRanges")
                }
                return null
            },
            section_cost: (key, content) => {
                return this.renderExtraOther(key, content, " zł")
            },

            default: (key, content) => this.renderExtraOther(key, content)
        }

        return (
            content &&
            config.map((section, index) => {
                const ifContentContainsSection =
                    content && section.sections.some(key => Object.keys(content).includes(key))

                if (ifContentContainsSection) {
                    return (
                        <div key={index} className={cx("section", "list")}>
                            {
                                <h1>
                                    {isProject || !section.nameOrganization ? section.name : section.nameOrganization}
                                </h1>
                            }
                            {section.sections.map(key => {
                                if (!content[key]) {
                                    return null
                                }

                                const renderer = sectionsRenderers[sectionsRenderers[key] ? key : "default"]
                                const rendererExtra = extraRenderers[extraRenderers[key] ? key : "default"]

                                const extraQuestionValue = rendererExtra(key, content)
                                const sectionTitle =
                                    isProject || (!isProject && !sectionsTitles[`${key}${"_organization"}`])
                                        ? sectionsTitles[key] && sectionsTitles[key].title
                                        : sectionsTitles[`${key}${"_organization"}`].title

                                return (
                                    <div key={key}>
                                        <p>{sectionTitle}</p>
                                        <p>{renderer(key, content).join(", ")}</p>
                                        {this.renderPointsForSection(key)}
                                        {section.extraQuestion && section.extraQuestion[key] && extraQuestionValue && (
                                            <>
                                                <p>{section.extraQuestion[key]}</p>
                                                <p>{extraQuestionValue.join(", ")}</p>
                                                {this.renderPointsForSection(key + "_additional")}
                                            </>
                                        )}
                                        {section.extraQuestion && section.extraQuestion[key] && content[key].store && (
                                            <>
                                                <p>{section.extraQuestion["store"]}</p>
                                                <p>
                                                    {idx(
                                                        dictionaries,
                                                        _ => _["stores"].data[content[key].store].address
                                                    )}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    )
                }
                return null
            })
        )
    }

    renderPointsForSection(key) {
        const { cx, submissions } = this.props
        const answers_points = idx(submissions, _ => _.current.data.answers_points)

        if (!answers_points) {
            return
        }

        const section = answers_points[key]

        if (!section) {
            return
        }

        let points = Number(section["points"])
        points = !isNaN(points) ? Math.round(points) : section["points"]

        return <span className={cx("points", { rejected: section["rejected"] })}>Punkty: {points}</span>
    }

    renderSupportForm = (key, content) => {
        const { dictionaries } = this.props
        const value = content[key].value
        const name = idx(dictionaries, _ => _.supportForms.data[value].name)
        return [name]
    }

    renderExtraOther = (key, content, suffix = "") => {
        if (content[key] && content[key].other) {
            return [content[key].other + suffix]
        }

        return null
    }

    renderExtra = (key, content, dictionary, extraDictionary) => {
        const { dictionaries } = this.props
        const additional = content[key].additional
        const value = idx(dictionaries, _ => _[dictionary].data)
        const valueExtra = idx(dictionaries, _ => _[extraDictionary].data)

        return Object.keys(additional).map(key => {
            if (!value[key]) {
                return []
            }

            if (additional[key] === SEPARATED_CHECKBOX_VALUE) {
                return [value[key].name + " (Nie wiem)"]
            }

            if (!valueExtra[additional[key]]) {
                return [value[key].name]
            }
            return [value[key].name + " (" + valueExtra[additional[key]].name + ")"]
        })
    }

    renderCheckboxes = (key, content, dictionary) => {
        const { dictionaries } = this.props
        if (content[key].isChecked === false) {
            return ["Nie"]
        }
        if (content[key].sponsors) {
            return content[key].sponsors
                .filter(sponsor => sponsor !== null)
                .map(sponsor => {
                    return sponsor
                })
        }

        const mediaLevel = {
            regional: "w regionalnych",
            national: "w krajowych",
            international: "w międzynarodowych"
        }

        if (key === "section_is_mass_media" && content[key].level_of_media && content[key].level_of_media.length > 0) {
            return [
                "Tak - " +
                    content[key].level_of_media.map(level => {
                        return " " + mediaLevel[level]
                    })
            ]
        }

        return (
            content[key].checkboxes &&
            content[key].checkboxes
                .sort((a, b) => a - b)
                .map(checkbox => {
                    if (checkbox === SEPARATED_CHECKBOX_VALUE) {
                        return ["Nie wiem"]
                    }

                    if (checkbox === OTHER_OPTION_VALUE) {
                        return "Inne: " + (content[key].additional ? content[key].additional[98] : "")
                    }

                    const value = idx(dictionaries, _ => _[dictionary].data[checkbox])
                    if (typeof value === "object") {
                        return value.name
                    }

                    if (key === "section_target_interests") {
                        const hasDescription = TARGET_INTERESTS_WITH_DESCRIPTION.some(check => check === checkbox)
                        return `${value}${hasDescription ? ` : ${content[key].additional[checkbox] || "-"}` : ""}`
                    }

                    return value
                })
        )
    }

    renderDropdownValue = (key, content, dictionary, suffix = "") => {
        const { dictionaries } = this.props
        if (content[key].value === OTHER_OPTION_VALUE) {
            return ["Inne" + (content[key].other ? ": " + content[key].other : "")]
        }
        const dictionaryValue = dictionaries[dictionary].data[content[key].value]

        if (!dictionaryValue) {
            return ["-"]
        }

        return [dictionaryValue.name + suffix]
    }

    renderBinaryState = (key, content) => {
        if (content[key].isChecked) return ["Tak"]
        return ["Nie"]
    }

    renderRange = (key, content) => {
        const isOneDayEvent = content[key].range.start === content[key].range.end

        if (isOneDayEvent) {
            return [trimMonthWord(content[key].range.start)]
        }
        return [trimMonthWord(content[key].range.start) + " - " + trimMonthWord(content[key].range.end)]
    }

    renderTitle() {
        const { cx, submissions } = this.props
        const eventType = idx(submissions, _ => _.current.data.content.section_event_type)

        return (
            <header className={cx("header")}>
                <h1>{eventType && eventType.name}</h1>
                <p className={cx("category")}>
                    {eventType && eventType.isChecked ? eventTypesConfig.event_project : eventTypesConfig.organization}
                </p>
            </header>
        )
    }

    renderDefault = () => {
        return "-"
    }

    toggleOpenChangeTypeModal = () => {
        this.setState(prevState => ({ isOpenChangeTypeModal: !prevState.isOpenChangeTypeModal }))
    }
}

export default withDictionaries()(withStyles(styles)(SubmissionDetailsPage))
