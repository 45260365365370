import React from "react"

import AbstractStore from "./AbstractStore"
import fetch from "helpers/fetch"

const user = JSON.parse(localStorage.getItem("user"))
export const AuthStoreContext = React.createContext()

class AuthStore extends AbstractStore {
    login = async ({ email, password }) => {
        await this.saveData({ key: "user", type: "request", data: {} })

        return new Promise((resolve, reject) => {
            fetch
                .post("/login", { email, password, remember: true })
                .then(response => {
                    const loginResponse = { ...response }
                    const token = loginResponse.jwt_token

                    fetch
                        .get("/users/account", {
                            "X-Authorization": token
                        })
                        .then(response => {
                            this.saveData({
                                key: "user",
                                data: {
                                    ...loginResponse.data,
                                    name: response.data.name
                                },
                                type: "success"
                            }).then(() => {
                                this.setState({ isAdmin: true })

                                localStorage.setItem(
                                    "user",
                                    JSON.stringify({
                                        email: email,
                                        token,
                                        name: response.data.name
                                    })
                                )

                                resolve()
                            })
                        })
                })
                .catch(response => {
                    reject(response)
                })
        })
    }

    logout = async () => {
        await this.saveData({
            key: "user",
            type: "initial",
            data: {}
        })

        localStorage.removeItem("user")

        return Promise.resolve()
    }

    state = {
        user: this.wrapRequestState({ type: "initial", data: user || {} }),
        isAdmin: !!(user && user.email && user.token),
        login: this.login,
        logout: this.logout
    }

    getContext() {
        return AuthStoreContext
    }
}

export default AuthStore
