import React from "react"

import idx from "idx"

import Table from "../Table"
import getCxFromStyles from "helpers/getCxFromStyles"
import styles from "../../index.module.css"

const cx = getCxFromStyles(styles)

const Address = props => {
    const address = idx(props.submissions, _ => _.current.data.content.section_address)

    return (
        <article className={cx("address")}>
            <h2>Adres organizacji</h2>
            <Table>
                <Table.Content>
                    <tr>
                        <td>Ulica</td>
                        <td>{address && address.street}</td>
                    </tr>
                    <tr>
                        <td>Kod pocztowy</td>
                        <td>{address && address.post_code}</td>
                    </tr>
                    <tr>
                        <td>Miejscowość</td>
                        <td>{address && address.city}</td>
                    </tr>
                </Table.Content>
            </Table>
        </article>
    )
}

export default Address
