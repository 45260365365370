export const SEPARATED_CHECKBOX_VALUE = "99"
export const OTHER_OPTION_VALUE = "98"
export const VALIDATION_IGNORED_SECTIONS = []
export const REQUIRED_NUMBER_OF_SPONSORS = 5
export const TARGET_INTERESTS_WITH_DESCRIPTION = ["17", "18"]

const isSuffix = REQUIRED_NUMBER_OF_SPONSORS !== 1
export const MESSAGES = {
    ANSWER_THE_QUESTION: "Odpowiedz na poniższe pytanie.",
    PICK_OWN_BRANDS: "Wybierz marki własne firmy Kaufland.",
    PICK_RANGE: "Wybierz zasięg.",
    FILL_TEXT_FIELD: "Proszę wypełnić to pole.",
    SPONSORS_ERROR: `Wymagany${isSuffix ? "ch" : ""} jest co najmniej ${REQUIRED_NUMBER_OF_SPONSORS} sponsor${
        isSuffix ? "ów" : ""
    }.`,
    ADD_ATTACHMENT: "Wgraj plik pdf",
    WRONG_VALUE: "Niepoprawna wartość.",
    MAX_5_WORDS: "Maksymalnie 5 słów.",
    MAX_100_WORDS: "Maksymalnie 100 słów.",
    MAX_300_WORDS: "Maksymalnie 300 słów.",
    MASS_MEDIA_ERROR_1: "Wybierz w jakich mediach sponsorowane wydarzenie / projekt będzie komunikowane.",
    MASS_MEDIA_ERROR_2: "Zaznacz kanały, w których sponsorowane wydarzenie / projekt będzie komunikowane.",
    MASS_MEDIA_ERROR_3: "Wybierz w jakich mediach, w jakich kanałach.",
    MASS_MEDIA_ERROR_4: "Wybierz zasięg w poszczególnych kanałach.",
    MASS_MEDIA_ERROR_5: "Wybierz w jakich mediach, jaki zasięg w poszczególnych kanałach.",
    INVALID_POSTAL_CODE: "Niepoprawny format kodu pocztowego.",
    INVALID_EMAIL: "Niepoprawny adres email.",
    INVALID_PHONE: "Niepoprawny numer telefonu.",
    INVALID_TERMS: "Akceptacja regulaminu jest wymagana.",
    NOTIFICATION_ERROR_HEADER: "Uuups...",
    NOTIFICATION_SUCCESS_HEADER: "Sukces"
}

export const STATUSES = {
    new: {
        text: "Oczekujące",
        color: "#0d7f95",
        backgroundColor: "#daf1f5",
        hint:
            "Jeśli zgłaszanie spełnia wymogi, to przyjmij do weryfikacji. W przeciwnym razie – odrzuć je. W przypadku gdy zgłoszenie będzie na pewno wsparte, zaakceptuj je."
    },
    in_progress: {
        text: "Weryfikowane",
        color: "#f97500",
        backgroundColor: "#ffeddd",
        hint:
            "Jeśli zgłoszanie zostanie wsparte, oznacz je jako zaakcpetowane. W przeciwnym razie oznacz je jako odrzucone."
    },
    accepted: {
        text: "Zaakceptowane",
        color: "#18a34e",
        backgroundColor: "rgba(186,237,205, 0.5)",
        title: "Zgłoszenie otrzyma wsparcie",
        description: "Skontaktuj się mailowo lub telefonicznie, aby uzgodnić dalsze kroki."
    },
    rejected: {
        text: "Odrzucone",
        color: "#e6374b",
        backgroundColor: "rgba(255, 72, 86, 0.2)",
        title: "Zgłoszenie odrzucone",
        description: "Zgłoszonie o wsparcie zostało odrzucone. Poniżej możesz zmienić jego status."
    },
    rejected_by_system: {
        text: "Odrzucone  (auto)",
        color: "#e6374b",
        backgroundColor: "rgba(255, 72, 86, 0.2)",
        title: "Automatycznie odrzucone",
        description:
            "Zgłoszonie o wsparcie nie spełniło wymagań platformy Kaufland Sponsoring i zostało automatycznie odrzucone."
    }
}

export const SUBMISSION_TYPE = {
    commercial: { text: "Sponsoring" },
    minisponsoring: { text: "Mini sponsoring" },
    non_profit: { text: "Darowizna" }
}
