const trimMonthWord = date => {
    const config = {
        '01': 'sty',
        '02': 'lut',
        '03': 'mar',
        '04': 'kwi',
        '05': 'maj',
        '06': 'cze',
        '07': 'lip',
        '08': 'sie',
        '09': 'wrz',
        '10': 'paź',
        '11': 'lis',
        '12': 'gru',
    }

    const formated = date.split('.')

    if (formated.length !== 3) {
        return date
    }

    formated[1] = config[formated[1]]
    return formated[0] + ' ' + formated[1] + ' ' + formated[2] + 'r.'
}

export default trimMonthWord