import React from "react"

import DropdownMenu from "components/forms/DropdownMenu"

import TrashIcon from "assets/ico-trash.module.svg"
import MailIcon from "assets/ico-mail.module.svg"

const SendOrDeleteDropdownMenu = ({ sendMail, deleteSubmission, mailSent }) => {
    const menuItems = [
        {
            value: "delete",
            children: (
                <>
                    <TrashIcon /> Usuń
                </>
            ),
            disabled: false
        },
        {
            value: "send",
            children: (
                <>
                    <MailIcon /> Wyślij wiadomość
                </>
            ),
            disabled: mailSent
        }
    ]
    const handleSendOrDelete = value => (value === "send" ? sendMail() : deleteSubmission())
    return (
        <DropdownMenu stopPropagation handleChange={handleSendOrDelete}>
            {menuItems
                .filter(({ disabled }) => !disabled)
                .map(menuItem => (
                    <span key={menuItem.value} {...menuItem} />
                ))}
        </DropdownMenu>
    )
}

export default SendOrDeleteDropdownMenu
