import React, { Component } from 'react'

import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'

class Table extends Component {

    render() {
        const { cx, children } = this.props

        return (
            <table className={cx("root")}>
                {children}
            </table>
        )
    }

}

export default withStyles(styles)(Table)