import React from "react"
import PropTypes from "prop-types"

import { Field, InputField, Button } from "components/forms"
import styles from "./index.module.css"
import resetPasswordStyles from "./overrides/ResetPassword.module.css"
import getCxFromStyles from "helpers/getCxFromStyles"
import { Formik } from "formik"
import { Modal } from "components/modal"
import validationSchema from "./resetPasswordSchema"
import Logo from 'assets/login-logo.module.svg'

const cx = getCxFromStyles(styles)

const ResetPasswordPage = ({ isOpen = true, isResetPassword = true, handleClose, handleSubmit }) => {
    return (
        <Modal isOpen={isOpen} handleClose={handleClose} customStyles={isResetPassword ? resetPasswordStyles : ''}>
            <Modal.Dialog>
                <Modal.Header handleClose={handleClose}>
                    <Modal.Title>{isResetPassword ? "Ustaw hasło" : "Zmień hasło"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={cx("root", { isResetPassword })}>
                        <Formik
                            initialValues={{ password: "", passwordConfirm: "" }}
                            onSubmit={handleSubmit}
                            validateOnChange={false}
                            validationSchema={validationSchema}
                            render={({ handleSubmit, isSubmitting, values, errors }) => {
                                return (
                                    <form className={cx("form")} onSubmit={handleSubmit}>
                                        {
                                            isResetPassword &&
                                            <Logo />
                                        }
                                        {
                                            !isResetPassword &&
                                            <Field
                                                headerLabel="Stare hasło"
                                                placeholder=""
                                                name="newPassword"
                                                type="password"
                                                component={InputField}
                                            />
                                        }
                                        <Field
                                            headerLabel={isResetPassword ? "Hasło" : "Nowe hasło"}
                                            placeholder=""
                                            name="password"
                                            type="password"
                                            component={InputField}
                                        />
                                        <Field
                                            headerLabel="Powtórz hasło"
                                            placeholder=""
                                            name="passwordConfirm"
                                            type="password"
                                            component={InputField}
                                        />
                                        {!isResetPassword && (
                                            <Button type="button" size="m-size" theme="white" onClick={handleClose}>
                                                Anuluj
                                            </Button>
                                        )}
                                        <Button type="submit" size="m-size" isLoading={isSubmitting}>
                                            {isResetPassword ? "Ustaw hasło" : "Zmień hasło"}
                                        </Button>
                                    </form>
                                )
                            }}
                        />
                    </div>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    )
}

ResetPasswordPage.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

export default ResetPasswordPage
