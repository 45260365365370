import React, { useContext } from "react"
import moment from "moment"

import formEventTypes from "config/formEventTypes"
import getCxFromStyles from "helpers/getCxFromStyles"
import styles from "./index.module.css"
import { DateRangePicker, Dropdown } from "components/forms"
import dateRangePickerStyles from "./overrides/DateRangePicker.module.css"
import dropdownStyles from "./overrides/Dropdown.module.css"

import { DictionariesStoreContext } from "store/DictionariesStore"
import { SubmissionsStoreContext } from "store/SubmissionsStore"

const cx = getCxFromStyles(styles)

const formatDateToAPI = date => moment(date, "DD.MM.YYYY").format("YYYY-MM-DD")

const Filters = props => {
    const { costs, fetchStatus } = useContext(DictionariesStoreContext)
    const {
        setFilterParam,
        filterParams: { cost, startDate, endDate, eventType }
    } = useContext(SubmissionsStoreContext)

    const dateRange = { startDate, endDate, key: "selection" }

    return (
        <div className={cx("root")}>
            <Dropdown
                name={"eventType"}
                customStyles={dropdownStyles}
                placeholder="Rodzaj zgłoszenia"
                handleChange={value => {
                    setFilterParam({ name: "eventType", value })
                }}
            >
                {costs &&
                    Object.keys(formEventTypes).map(key => {
                        const name = formEventTypes[key]
                        return (
                            <Dropdown.Item key={key} value={key} selected={eventType === key}>
                                {name}
                            </Dropdown.Item>
                        )
                    })}
            </Dropdown>

            <Dropdown
                name={"cost"}
                customStyles={dropdownStyles}
                placeholder="Kwota wsparcia"
                isLoading={fetchStatus.isLoading}
                handleChange={value => {
                    setFilterParam({ name: "cost", value })
                }}
            >
                {costs &&
                    Object.keys(costs.data).map(key => {
                        const name = costs.data[key].name
                        return (
                            <Dropdown.Item key={key} value={key} selected={cost === key}>
                                {name} tys. PLN
                            </Dropdown.Item>
                        )
                    })}
            </Dropdown>

            <DateRangePicker
                type="dropdown"
                align="left"
                customStyles={dateRangePickerStyles}
                ranges={[dateRange]}
                isReturnInitialDate={false}
                placeholder={"Ramy czasowe"}
                handleChange={range => {
                    setFilterParam({
                        name: "range",
                        value: {
                            startDate: formatDateToAPI(range.start),
                            endDate: formatDateToAPI(range.end)
                        }
                    })
                }}
                handleReset={() => {
                    setFilterParam({
                        name: "range",
                        value: {
                            startDate: null,
                            endDate: null
                        }
                    })
                }}
            />
        </div>
    )
}

export default Filters
