const scenarios = {
    commercial: {
        steps: [0, 1, 2, 3, 4],
        excludedSections: []
    },
    commercial_mini: {
        steps: [0, 1, 2, 3, 4],
        excludedSections: ["section_support_form", "section_target_interests"]
    },
    non_profit: {
        steps: [0, 1, 4],
        excludedSections: ["section_own_brands"]
    },
    non_profit_mini: {
        steps: [0, 1, 4],
        excludedSections: ["section_support_form", "section_own_brands"]
    },
    private: {
        steps: [0],
        excludedSections: [
            "section_support_initiative",
            "section_own_brand_promotion",
            "section_is_sale_document",
            "section_is_previous_support",
            "section_cost",
            "section_support_form"
        ]
    }
}

export default scenarios
