import React from "react"

const withAbstractStoreHOC = ({ Context, storeKey = "store" }) => {
    return WrappedComponent => {
        return props => (
            <Context.Consumer>
                {values => {
                    let enhancedProps = {
                        ...props,
                        [storeKey]: {
                            ...values
                        }
                    }
                    return <WrappedComponent {...enhancedProps} />
                }}
            </Context.Consumer>
        )
    }
}

export default withAbstractStoreHOC
