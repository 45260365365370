import fetch from "isomorphic-fetch"
import { API } from "env"

const request = ({ endpoint, method, headers, body = null, raw = false }) => {
    const options = {
        method,
        headers: {
            ...headers,
            ...API.authorizationHeaders,
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    }

    body && (options.body = JSON.stringify(body))
    return fetch(API.baseUrl + endpoint, options).then(response => {
        if (!response.ok) {
            if (response.status === 401) {
                window.location.href = "/panel/wyloguj"
            }
            if (response.status === 422) {
                return response.json().then(json => Promise.reject(json))
            }
            throw new DOMException(response.statusText)
        }
        return !raw ? response.json() : response
    })
}

export default {
    get: (endpoint, headers = {}) => {
        return request({ endpoint, headers, method: "GET" })
    },
    getRAW: (endpoint, headers = {}) => {
        return request({ endpoint, method: "GET", headers, raw: true })
    },
    post: (endpoint, body, headers = {}) => {
        return request({ endpoint, method: "POST", body, headers })
    },
    postRAW: (endpoint, body, headers = {}) => {
        return request({ endpoint, method: "POST", body, headers, raw: true })
    },
    patch: (endpoint, body, headers = {}) => {
        return request({ endpoint, method: "PATCH", body, headers })
    },
    delete: (endpoint, headers = {}) => {
        return request({ endpoint, method: "DELETE", headers })
    }
}
