import { OTHER_OPTION_VALUE, MESSAGES } from "../constances"

export const validateRadioSectionInputField = ({ isChecked, name }) => {
    if (isChecked && !name) {
        return { message: "", additional: { name: MESSAGES.FILL_TEXT_FIELD } }
    }
}

export const validateSupportInitiativeInputField = ({ value }) => {
    if (!value) {
        return { message: MESSAGES.FILL_TEXT_FIELD, additional: {} }
    }
}

export const validateSectionBeneficiaries = ({ checkboxes }) => {
    if (checkboxes && !checkboxes.length) {
        return { message: MESSAGES.FILL_TEXT_FIELD, additional: {} }
    }
}

export const validateEventTypeInputField = ({ name, other }) => {
    let isError = false
    const additionalErrors = {}

    if (!name) {
        isError = true
        additionalErrors.name = MESSAGES.FILL_TEXT_FIELD
    } else if (isMoreWordsThan(100, name)) {
        isError = true
        additionalErrors.name = MESSAGES.MAX_100_WORDS
    }
    if (isMoreWordsThan(100, other)) {
        isError = true
        additionalErrors.other = MESSAGES.MAX_100_WORDS
    }
    if (isError) {
        return { message: "", additional: additionalErrors }
    }
}

export const validateSupportFormInputField = ({ value, name }) => {
    if (value === "material" && !name) {
        return { message: "", additional: { name: MESSAGES.FILL_TEXT_FIELD } }
    }
}

export const validateDropdown = ({ value, other = "" }) => {
    if (value === OTHER_OPTION_VALUE) {
        if (!other) {
            return { message: "", additional: { other: MESSAGES.FILL_TEXT_FIELD } }
        }
        if (isMoreWordsThan(5, other)) {
            return { message: "", additional: { other: MESSAGES.MAX_5_WORDS } }
        }
    }
}

export const validateMassMedia = ({ isChecked, level_of_media = [], checkboxes = [], additional = {} }) => {
    if (isChecked) {
        const levelOfMediaChecked = !!(level_of_media && level_of_media.length)
        const typesOfMediaChecked = !!(checkboxes && checkboxes.length)

        let message = ""
        if (!levelOfMediaChecked) {
            message = MESSAGES.MASS_MEDIA_ERROR_1
        }

        if (!typesOfMediaChecked) {
            message = levelOfMediaChecked ? MESSAGES.MASS_MEDIA_ERROR_2 : MESSAGES.MASS_MEDIA_ERROR_3
        }

        if (message) {
            return { message, additional: {} }
        }
    }
}

export const validateCheckboxes = ({
    checkboxes = [],
    additional = {},
    notAnyCheckedMessage = MESSAGES.ANSWER_THE_QUESTION
}) => {
    const isAnyChecked = !!(checkboxes && checkboxes.length)
    if (!isAnyChecked) {
        return { message: notAnyCheckedMessage, additional: {} }
    }

    const additionalErrors = checkboxes.reduce((output, key) => {
        if (additional.hasOwnProperty(key)) {
            if (!additional[key]) {
                output[key] = MESSAGES.FILL_TEXT_FIELD
            } else if (isMoreWordsThan(5, additional[key])) {
                output[key] = MESSAGES.MAX_5_WORDS
            }
        }
        return output
    }, {})

    if (Object.keys(additionalErrors).length) {
        return { message: "", additional: additionalErrors }
    }
}

export const validateCheckboxesInRadioSection = ({ isChecked, ...values }) => {
    if (isChecked) {
        return validateCheckboxes(values)
    }
}

export const validateOwnBrands = ({ isChecked, ...values }) => {
    if (isChecked) {
        return validateCheckboxes({ ...values, notAnyCheckedMessage: MESSAGES.PICK_OWN_BRANDS })
    }
}

export const validateAddress = ({ street = "", post_code = "", city = "" }) => {
    const additionalErrors = validateEmptyFields({ street, post_code, city })

    if (post_code && !post_code.match(/^\d{2}-\d{3}$/)) {
        additionalErrors.post_code = MESSAGES.INVALID_POSTAL_CODE
    }

    if (Object.keys(additionalErrors).length) {
        return { message: "", additional: additionalErrors }
    }
}

export const validatePersonalData = ({ name = "", surname = "", email = "", phone = "", terms = null }) => {
    const additionalErrors = validateEmptyFields({ name, surname, email, phone })

    if (email && !validateEmail(email)) {
        additionalErrors.email = MESSAGES.INVALID_EMAIL
    }

    if (phone && !phone.match(/\d{9,12}/)) {
        additionalErrors.phone = MESSAGES.INVALID_PHONE
    }
    if (!terms && terms !== true) {
        additionalErrors.terms = MESSAGES.INVALID_TERMS
    }

    if (Object.keys(additionalErrors).length) {
        return { message: "", additional: additionalErrors }
    }
}

export const validateCostInputField = ({ value, other, store }, sections) => {
    const otherNumber = other && Number(other.replace(",", "."))

    const errors = {
        other: null,
        store: null
    }

    const hasErrors = errors =>
        Object.keys(errors).reduce((acc, key) => {
            acc = acc || !!errors[key]
            return acc
        }, false)

    if (value === "1") {
        if (!other) {
            errors.other = MESSAGES.FILL_TEXT_FIELD
        } else if (!other.match(/^[0-9]+([,.][0-9]+)?$/g) || otherNumber > 6000) {
            errors.other = MESSAGES.WRONG_VALUE
        }

        if (
            ((sections || {}).section_is_support_behalf_organisation || {}).isChecked === true ||
            ((sections || {}).section_support_type || {}).isChecked === false
        ) {
            if (!store) {
                errors.store = MESSAGES.FILL_TEXT_FIELD
            }
        }
    }

    if (hasErrors(errors)) {
        return {
            message: "",
            additional: Object.keys(errors).reduce((acc, key) => {
                if (errors[key]) {
                    acc[key] = errors[key]
                }

                return acc
            }, {})
        }
    }
}

export const validateOwnCampaign = ({ isChecked, other }) => {
    if (isChecked) {
        if (!other) {
            return { message: "", additional: { other: MESSAGES.FILL_TEXT_FIELD } }
        } else if (isMoreWordsThan(100, other)) {
            return { message: "", additional: { other: MESSAGES.MAX_100_WORDS } }
        }
    }
}

function validateEmptyFields(fields = {}) {
    return Object.keys(fields).reduce((errors, fieldName) => {
        if (!fields[fieldName]) {
            errors[fieldName] = MESSAGES.FILL_TEXT_FIELD
        }
        return errors
    }, {})
}

function validateEmail(email) {
    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(String(email).toLowerCase())
}

function isMoreWordsThan(length, text) {
    return text && text.split(" ").length > length
}
