import React from "react"
import { withStyles } from "@mindz/react-hoc"

import MoreIcon from "assets/ico-more.module.svg"

import styles from "./indicator.module.css"

const IndicatorDefault = ({ cx }) => {
    return (
        <div className={cx("root")}>
            <MoreIcon />
        </div>
    )
}

export default withStyles(styles)(IndicatorDefault)
