import React, { Component } from 'react'

import { withStyles } from '@mindz/react-hoc'

import Header from 'components/Header'
import styles from './index.module.css'

class AdminLayout extends Component {
    render() {
        const { cx, children } = this.props

        return (
            <div className={cx('root')}>
                <Header />
                <section className={cx('content')}>
                    <div className="container admin">{children}</div>
                </section>
            </div>
        )
    }
}

export default withStyles(styles)(AdminLayout)
