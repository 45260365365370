import {
    validateRadioSectionInputField,
    validateEventTypeInputField,
    validateSupportFormInputField,
    validateOwnBrands,
    validateDropdown,
    validateMassMedia,
    validateCheckboxes,
    validateCheckboxesInRadioSection,
    validateAddress,
    validatePersonalData,
    validateCostInputField,
    validateOwnCampaign,
    validateSupportInitiativeInputField,
    validateSectionBeneficiaries
} from "./formValidation"

const steps = [
    {
        name: "Typ i forma wsparcia",
        sections: [
            "section_is_support_behalf_organisation",
            "section_support_initiative",
            "section_own_brand_promotion",
            "section_is_sale_document",
            "section_is_previous_support",
            "section_cost",
            "section_support_form"
        ],
        validate: {
            section_support_initiative: validateSupportInitiativeInputField,
            section_support_form: validateSupportFormInputField,
            section_cost: validateCostInputField
        }
    },
    {
        name: "Informacje o przedmiocie wsparcia",
        sections: [
            "section_concern_area",
            "section_support_range",
            "section_event_type",
            "section_event_date",
            "section_own_brands"
        ],
        validate: {
            section_concern_area: validateDropdown,
            section_own_brands: validateOwnBrands,
            section_event_type: validateEventTypeInputField
        }
    },
    {
        name: "Uczestnicy wydarzenia / projektu",
        sections: [
            "section_beneficiaries",
            "section_target_interests",
            "section_active_contestants_number",
            "section_audience_contestants_number"
        ],
        validate: {
            section_beneficiaries: validateSectionBeneficiaries,
            section_target_interests: validateCheckboxes
        }
    },
    {
        name: "Sponsorzy i reklama",
        sections: [
            "section_is_mass_media",
            "section_is_outdoor",
            "section_is_competitive_sponsors",
            "section_is_own_campaign"
        ],
        validate: {
            section_is_mass_media: validateMassMedia,
            section_is_competitive_sponsors: validateCheckboxesInRadioSection,
            section_is_own_campaign: validateOwnCampaign
        }
    },
    {
        name: "Dane o organizatorze",
        sections: ["section_is_kaufland_employee", "section_address", "section_attachment", "section_representant"],
        validate: {
            section_is_political_involvment: validateRadioSectionInputField,
            section_is_kaufland_employee: validateRadioSectionInputField,
            section_address: validateAddress,
            section_representant: validatePersonalData
        }
    }
]

export default steps
