import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import Portal from '../Portal'
import RootModal from '../RootModal'
import styles from './index.module.css'

class Modal extends Component {
    static defaultProps = {
        isOpen: false,
        handleClose: () => {}
    }

    render() {
        const { cx, isOpen, handleClose, children } = this.props

        if (!isOpen) {
            return null
        }

        return (
            <Portal>
                <RootModal handleClose={handleClose}>
                    <div className={cx('root')}>{children}</div>
                </RootModal>
            </Portal>
        )
    }
}

export default withStyles(styles)(Modal)
