import React from "react"

import { urlHelpers } from "@mindz/react-helpers"
import AbstractStore from "./AbstractStore"
import fetch from "helpers/fetch"
import withAuth from "HOC/withAuth"
import { NotificationManager } from "react-notifications"
import { MESSAGES } from "constances"
import moment from "moment"
const { objToQueryString } = urlHelpers

export const SubmissionsStoreContext = React.createContext()

class SubmissionsStore extends AbstractStore {
    fetchCurrentSubmission = async id => {
        const appToken = this.getAppToken()

        if (!appToken) {
            return
        }

        await this.saveData({
            key: "current",
            type: "request",
            data: {}
        })

        return fetch
            .get(`/forms/${id}`, {
                "X-Authorization": appToken
            })
            .then(response => {
                const { data } = response

                this.saveData({
                    key: "current",
                    type: "success",
                    data: { ...data }
                })
            })
            .catch(err => {
                this.saveData({
                    key: "current",
                    type: "failure",
                    data: {}
                })
            })
    }

    fetchSubmissions = async () => {
        const appToken = this.getAppToken()

        if (!appToken) {
            return
        }

        const { filterParams, list } = this.state
        const queryString = objToQueryString(filterParams, true)

        if (filterParams.page === 1) {
            await this.saveData({
                key: "list",
                type: "request",
                data: [...list.data],
                meta: { ...list.meta }
            })
        }

        return fetch
            .get(`/forms?${queryString}`, {
                "X-Authorization": appToken
            })
            .then(response => {
                const { data, meta, statusesCount } = response

                this.saveData({
                    key: "list",
                    type: "success",
                    data: filterParams.page === 1 ? data : [...list.data, ...data],
                    meta: { ...meta, statusesCount }
                })
            })
            .catch(err => {
                this.saveData({
                    key: "list",
                    type: "failure",
                    data: [],
                    meta: {}
                })
            })
    }

    patchSubmissionStatus = (id, status) => {
        const appToken = this.getAppToken()

        if (!appToken) {
            return
        }

        return fetch
            .patch(
                `/forms/${id}`,
                { status },
                {
                    "X-Authorization": appToken
                }
            )
            .then(() => {
                this.fetchCurrentSubmission(id)
            })
            .catch(err => {
                NotificationManager.error(
                    "Nie udało się zaktualizować statusu. Spróbuj jeszcze raz.",
                    MESSAGES.NOTIFICATION_ERROR_HEADER
                )
            })
    }

    patchSubmissionType = (id, type) => {
        const appToken = this.getAppToken()

        if (!appToken) {
            return
        }

        return fetch
            .patch(
                `/forms/change-type/${id}`,
                { type },
                {
                    "X-Authorization": appToken
                }
            )
            .then(() => {
                NotificationManager.success("Typ zgłoszenia został zmieniony.", MESSAGES.NOTIFICATION_SUCCESS_HEADER)
            })
            .catch(err => {
                NotificationManager.error(
                    "Nie udało się zaktualizować typu. Spróbuj jeszcze raz.",
                    MESSAGES.NOTIFICATION_ERROR_HEADER
                )
            })
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { filterParams } = this.state

        if (JSON.stringify(filterParams) !== JSON.stringify(prevState.filterParams)) {
            this.fetchSubmissions()
        }
    }

    getAppToken() {
        const {
            auth: {
                isAdmin,
                user: {
                    data: { token }
                }
            }
        } = this.props

        if (!isAdmin) {
            return null
        }

        return token
    }

    setFilterParam = ({ name, value }) => {
        const { filterParams } = this.state

        let newParams =
            name === "range"
                ? {
                      ...filterParams,
                      ...value
                  }
                : {
                      ...filterParams,
                      [name]: value
                  }

        if (name !== "page") {
            newParams["page"] = 1
        }

        this.setState({
            filterParams: newParams
        })
    }

    exportToFile = ({ type = "xls", id } = {}) => {
        const appToken = this.getAppToken()

        if (!appToken) {
            return
        }

        const todayDate = moment(new Date()).format("YYYYMMDD")
        const { filterParams } = this.state
        const queryString = objToQueryString({ ...filterParams, export: "xls", page: 1, length: 10000 }, true)
        const [endpoint, filename] =
            type === "xls"
                ? [`/forms?${queryString}`, `kaufland-sponsoring-lista-zgloszen-${todayDate}.xls`]
                : [`/forms/${id}/pdf`, "zgloszenie.pdf"]
        this.setState({
            isExporting: true
        })

        return fetch
            .getRAW(endpoint, {
                "X-Authorization": appToken
            })
            .then(response => response.blob())
            .then(blob => {
                const blobUrl = URL.createObjectURL(blob)
                const link = document.createElement("a")
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, filename)
                }

                if (!navigator.msSaveBlob) {
                    link.style.display = "none"
                    link.setAttribute("href", blobUrl)
                    link.setAttribute("download", filename)
                    document.body.appendChild(link)
                    link.click()
                }

                setTimeout(() => {
                    document.body.removeChild(link)
                    this.setState({
                        isExporting: false
                    })
                }, 1000)
            })
            .catch(err => {
                NotificationManager.error(
                    "Nie udało się pobrać pliku. Spróbuj jeszcze raz.",
                    MESSAGES.NOTIFICATION_ERROR_HEADER
                )
                this.setState({
                    isExporting: false
                })
            })
    }

    sendMessageToRejected = (submissions, fetchCurrentSubmission) => {
        const appToken = this.getAppToken()

        if (!appToken) {
            return
        }

        const { filterParams } = this.state
        const queryString = objToQueryString({ ...filterParams, sendRejectInfo: "send", page: 1 }, true)

        const shouldSendAll = !!submissions
        const header = { "X-Authorization": appToken }
        const body = shouldSendAll ? { rejectedFormsIds: submissions } : null
        const sendMailFetch = shouldSendAll
            ? fetch.post("/forms/send-reject-info", body, header)
            : fetch.get(`/forms?${queryString}`, header)

        const getEndOfWord = (singular, plural) => (shouldSendAll ? plural : singular)

        return sendMailFetch
            .then(() =>
                fetchCurrentSubmission ? this.fetchCurrentSubmission(submissions[0]) : this.fetchSubmissions()
            )
            .then(() =>
                NotificationManager.success(
                    `Wiadomoś${getEndOfWord("ć", "ci")} wysłan${getEndOfWord("a", "e")} pomyślnie.`,
                    MESSAGES.NOTIFICATION_SUCCESS_HEADER
                )
            )
            .catch(err => {
                NotificationManager.error(
                    "Nie udało się wysłać wiadomości. Spróbuj jeszcze raz.",
                    MESSAGES.NOTIFICATION_ERROR_HEADER
                )
            })
    }

    deleteSubmission = (submissions, redirectToList) => {
        const appToken = this.getAppToken()

        if (!appToken) {
            return
        }

        const { filterParams } = this.state
        const queryString = objToQueryString(
            { ...filterParams, deleteRejected: "delete", page: 1, length: 10000 },
            true
        )

        const shouldDeleteAll = !!submissions
        const header = { "X-Authorization": appToken }
        const body = { formsIds: submissions }
        const deleteFetch = shouldDeleteAll
            ? fetch.post("/forms/delete", body, header)
            : fetch.get(`/forms?${queryString}`, header)

        const getEndOfWord = (singular, plural) => (shouldDeleteAll ? plural : singular)

        return deleteFetch
            .then(() => (!!redirectToList ? redirectToList() : this.fetchSubmissions()))
            .then(() =>
                NotificationManager.success(
                    `Zgłoszeni${getEndOfWord("e", "a")} został${getEndOfWord("o", "y")} usunięte.`,
                    MESSAGES.NOTIFICATION_SUCCESS_HEADER
                )
            )
            .catch(err => {
                NotificationManager.error(
                    `Nie udało się usunąć zgłosze${getEndOfWord("nia", "ń")}. Spróbuj jeszcze raz.`,
                    MESSAGES.NOTIFICATION_ERROR_HEADER
                )
            })
    }

    state = {
        list: this.wrapRequestState({ type: "initial", data: [] }),
        current: this.wrapRequestState({ type: "initial", data: {} }),
        filterParams: {
            type: null,
            cost: null,
            status: "new",
            startDate: null,
            endDate: null,
            page: 1,
            length: 10
        },
        setFilterParam: this.setFilterParam,
        fetchCurrentSubmission: this.fetchCurrentSubmission,
        fetchSubmissions: this.fetchSubmissions,
        exportToFile: this.exportToFile,
        patchSubmissionStatus: this.patchSubmissionStatus,
        patchSubmissionType: this.patchSubmissionType,
        sendMessageToRejected: this.sendMessageToRejected,
        deleteSubmission: this.deleteSubmission,
        isExporting: false
    }

    getContext() {
        return SubmissionsStoreContext
    }
}

export default withAuth()(SubmissionsStore)
