import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'
import styles from './index.module.css'

class SubHeader extends Component {
    render() {
        const { cx, title, children } = this.props

        return (
            <header className={cx('root')}>
                <div className={cx('container')}>
                    <h1>
                        {title}
                    </h1>
                    {children}
                </div>
            </header>
        )
    }
}

export default withStyles(styles)(SubHeader)
